import { all } from "redux-saga/effects";
import settingSaga from "../src/shared/store/setting.saga";
import homeSaga from "../src/screens/components/Home/home.saga";
import loginSaga from "../src/screens/components/Login/login.saga";
import contactSaga from "./screens/components/Contact/contact.saga";
import signupSaga from "../src/screens/components/SignUp/signup.saga";
import profileSaga from "../src/screens/components/Profile/profile.saga";
import sourceSaga from "./screens/shared/components/UserRegisterationSourceModal/saga";
import timelineTaskListSaga from "../src/screens/components/Timeline/timeline.saga";
import changePasswordSaga from "./screens/components/ChangePassword/change-password.saga";
import resetPasswordSaga from "../src/screens/components/ResetPassword/resetPassword.saga";
import forgotPasswordSaga from "../src/screens/components/ForgotPassword/forgotPassword.saga";
import timelineResourcesSaga from "../src/screens/components/TimelineResources/timelineResources.saga";
import statementOfBenefitsSaga from "../src/screens/components/StatementOfBenefits/statementOfBenefits.saga";
import transitionProgramSteps from "../src/screens/components/TransitionProgramSteps/transitionProgramSteps.saga";
import newsSaga from "../src/screens/components/News/news.saga";
import advertisementSaga from "../src/shared/components/Advertisement/advertisement.saga";
import timelineListSaga from "../src/screens/shared/components/TimelineList/saga";
import weeklyNewsletterUnsubscribe from "../src/screens/components/WeeklyNewsletterUnsubscribe/saga";
import employerSaga from "./screens/components/VeteranEmployers/saga/employer.saga";
import deleteSaga from "./screens/components/DeleteAccount/delete.saga";

export default function* rootSaga() {
  yield all([
    ...homeSaga,
    ...loginSaga,
    ...signupSaga,
    ...sourceSaga,
    ...settingSaga,
    ...profileSaga,
    ...contactSaga,
    ...resetPasswordSaga,
    ...forgotPasswordSaga,
    ...changePasswordSaga,
    ...timelineTaskListSaga,
    ...timelineResourcesSaga,
    ...transitionProgramSteps,
    ...statementOfBenefitsSaga,
    ...newsSaga,
    ...advertisementSaga,
    ...timelineListSaga,
    ...weeklyNewsletterUnsubscribe,
    ...employerSaga,
    ...deleteSaga
  ]);
}
