import { ActionType } from "./../../../../shared/types/redux";
import Actions from "./actions";

const defaultState = {
  unsubscribeFromWeeklynNewsletterInProgress: false,
  unsubscribeFromWeeklynNewsletterSuccess: false,
  unsubscribeFromWeeklynNewsletterFailed: false,
};

const reducer = (
  state = defaultState,
  action: ActionType<any>
) => {
  switch (action.type) {
    case Actions.UNSUBSCRIBE_FROM_WEEKLY_NEWSLETTER:
      return {
        ...state,
        unsubscribeFromWeeklynNewsletterInProgress: true,
        unsubscribeFromWeeklynNewsletterSuccess: false,
        unsubscribeFromWeeklynNewsletterFailed: false,
      };
    case Actions.UNSUBSCRIBE_FROM_WEEKLY_NEWSLETTER_SUCCESS:
      return {
        ...state,
        unsubscribeFromWeeklynNewsletterInProgress: false,
        unsubscribeFromWeeklynNewsletterSuccess: true,
        unsubscribeFromWeeklynNewsletterFailed: false,
      };
    case Actions.UNSUBSCRIBE_FROM_WEEKLY_NEWSLETTER_FAILED:
      return {
        ...state,
        unsubscribeFromWeeklynNewsletterInProgress: false,
        unsubscribeFromWeeklynNewsletterSuccess: false,
        unsubscribeFromWeeklynNewsletterFailed: true,
      };
    default:
      return { ...state };
  }
};

export default reducer;
