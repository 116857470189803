import { takeLatest, call, put } from "redux-saga/effects";
import Actions from "./store/action";
import Api from "../../../shared/utils/Api";
import Toastr from "../../../shared/utils/Toastr";

function resetPasswordApiCall(details) {
    const password = {
        password: details.password
    }
    return Api.put(`account/reset-password/${details.token}`, password);
}

function* watchForgotPasswordAsync(data) {
    try {
        const details = yield call(resetPasswordApiCall, data.data);
        yield put({
            type: Actions.UPDATE_RESET_PASSWORD_SUCCESS,
            details
        });
        Toastr.success(`Reset Password Successfully...!!!`);
    } catch ({ errors }) {
        Toastr.error(errors.message);
        yield put({
            type: Actions.UPDATE_RESET_PASSWORD_FAIL,
            data: errors
        });
    }
}

const resetPasswordSaga = [takeLatest(Actions.UPDATE_RESET_PASSWORD_FETCHED, watchForgotPasswordAsync)]
export default resetPasswordSaga;


