import Actions from "./actions";

const defaultState = {
  fetchNewsInProgress: false,
  fetchNewsSuccess: false,
  fetchNewsFailed: false,
  newsError: null,
  news: [],
  newsCount: 0,

  fetchNewsTagsInProgress: false,
  fetchNewsTagsSuccess: false,
  fetchNewsTagsFailed: false,
  tagsError: null,
  tagList: [],

  fetchNewsCategoriesInProgress: false,
  fetchNewsCategoriesSuccess: false,
  fetchNewsCategoriesFailed: false,
  categoriesError: null,
  categoryList: [],
};

function news(state = defaultState, action) {
  switch (action.type) {
    case Actions.FETCH_NEWS:
      return {
        ...state,
        fetchNewsInProgress: true,
        fetchNewsSuccess: false,
        fetchNewsFailed: false,
        news: [],
      };
    case Actions.FETCH_NEWS_SUCCESS:
      return {
        ...state,
        fetchNewsInProgress: false,
        fetchNewsSuccess: true,
        fetchNewsFailed: false,
        news: [...action.data.newsList],
        newsCount: action.data.hasOwnProperty("newsCount")
          ? action.data.newsCount
          : state.newsCount,
      };
    case Actions.FETCH_NEWS_FAILED:
      return {
        ...state,
        fetchNewsInProgress: false,
        fetchNewsSuccess: false,
        fetchNewsFailed: true,
        newsError: action.data,
      };
    //categories
    case Actions.FETCH_NEWS_CATEGORIES:
      return {
        ...state,
        fetchNewsCategoriesInProgress: true,
        fetchNewsCategoriesSuccess: false,
        fetchNewsCategoriesFailed: false,
      };
    case Actions.FETCH_NEWS_CATEGORIES_SUCCESS:
      return {
        ...state,
        fetchNewsCategoriesInProgress: false,
        fetchNewsCategoriesSuccess: true,
        fetchNewsCategoriesFailed: false,
        categoryList: [...action.data.newsCategoryList],
      };
    case Actions.FETCH_NEWS_CATEGORIES_FAILED:
      return {
        ...state,
        fetchNewsCategoriesInProgress: false,
        fetchNewsCategoriesSuccess: false,
        fetchNewsCategoriesFailed: true,
        categoriesError: action.data,
      };
    //tags
    case Actions.FETCH_NEWS_TAGS:
      return {
        ...state,
        fetchNewsTagsInProgress: true,
        fetchNewsTagsSuccess: false,
        fetchNewsTagsFailed: false,
      };
    case Actions.FETCH_NEWS_TAGS_SUCCESS:
      return {
        ...state,
        fetchNewsTagsInProgress: false,
        fetchNewsTagsSuccess: true,
        fetchNewsTagsFailed: false,
        tagList: [...action.data.newsTagsList],
      };
    case Actions.FETCH_NEWS_TAGS_FAILED:
      return {
        ...state,
        fetchNewsTagsInProgress: false,
        fetchNewsTagsSuccess: false,
        fetchNewsTagsFailed: true,
        tagsError: action.data,
      };
    default:
      return { ...state };
  }
}

export default news;
