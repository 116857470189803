const Actions = {
    GET_CITIES: 'GET_CITIES',
    GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS',
    GET_CITIES_FAIL: 'GET_CITIES_FAIL',

    GET_INDUSTRY_BY_CITY: 'GET_INDUSTRY_BY_CITY',
    GET_INDUSTRY_BY_CITY_SUCCESS: 'GET_INDUSTRY_BY_CITY_SUCCESS',
    GET_INDUSTRY_BY_CITY_FAIL: 'GET_INDUSTRY_BY_CITY_FAIL',

    GET_INDUSTRY_NAME_ID: 'GET_INDUSTRY_NAME_ID',

    GET_INDUSTRY_ID_BOTH: 'GET_INDUSTRY_ID_BOTH',
    GET_INDUSTRY_ID_BOTH_SUCCESS: 'GET_INDUSTRY_ID_BOTH_SUCCESS',
    GET_INDUSTRY_ID_BOTH_FAIL: 'GET_INDUSTRY_ID_BOTH_FAIL',

    GET_COMPANY_DETAILS: 'GET_COMPANY_DETAILS',
    GET_COMPANY_DETAILS_SUCCESS: 'GET_COMPANY_DETAILS_SUCCESS',
    GET_COMPANY_DETAILS_FAIL: 'GET_COMPANY_DETAILS_FAIL'
}

export default Actions