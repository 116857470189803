const Actions = {
  ON_RESET_STATE: "ON_RESET_STATE",
  ON_ROUTE_CHANGE: "ON_ROUTE_CHANGE",
  ON_UPDATE_CHECKBOX_VALUE: "ON_UPDATE_CHECKBOX_VALUE",

  // Transition Progarm steps
  ON_TIMELINE_TASK_FETCHED: "ON_TIMELINE_TASK_FETCHED",
  ON_TIMELINE_TASK_FETCHED_SUCCESS: "ON_TIMELINE_TASK_FETCHED_SUCCESS",
  ON_TIMELINE_TASK_FETCHED_FAIL: "ON_TIMELINE_TASK_FETCHED_FAIL",
  ON_UPDATE_TASK_STATUS: "ON_UPDATE_TASK_STATUS",

  ON_UPLOAD_TASK_STATUS: "ON_UPLOAD_TASK_STATUS",
  ON_UPLOAD_TASK_STATUS_SUCCESS: "ON_UPLOAD_TASK_STATUS_SUCCESS",
  ON_UPLOAD_TASK_STATUS_FAIL: "ON_UPLOAD_TASK_STATUS_FAIL",

  // Timeline steps fetched
  ON_TIMELINE_STEPS_FETCH: "ON_TIMELINE_STEPS_FETCH",
  ON_TIMELINE_STEPS_FETCH_SUCCESS: "ON_TIMELINE_STEPS_FETCH_SUCCESS",
  ON_TIMELINE_STEPS_FETCH_FAIL: "ON_TIMELINE_STEPS_FETCH_FAIL",

  //statement of benefits
  ON_STATEMENT_OF_BENEFITS_API_FETCH: "ON_STATEMENT_OF_BENEFITS_API_FETCH",
  ON_STATEMENT_OF_BENEFITS_API_FETCH_FAIL:
    "ON_STATEMENT_OF_BENEFITS_API_FETCH_FAIL",
  ON_STATEMENT_OF_BENEFITS_API_FETCH_SUCCESS:
    "ON_STATEMENT_OF_BENEFITS_API_FETCH_SUCCESS",

  //feedback
  ON_FEEDBACK_OPTION_CHANGE: "ON_FEEDBACK_OPTION_CHANGE"
};

export default Actions;
