const Actions = {
  ON_RESET_STATE: "ON_RESET_STATE",

  FETCH_PROFILE: "FETCH_PROFILE",
  FETCH_PROFILE_SUCCESS: "FETCH_PROFILE_SUCCESS",
  FETCH_PROFILE_FAILED: "FETCH_PROFILE_FAILED",

  UPLOAD_PROFILE_IMAGE: "UPLOAD_PROFILE_IMAGE",
  UPLOAD_PROFILE_IMAGE_SUCCESS: "UPLOAD_PROFILE_IMAGE_SUCCESS",
  UPLOAD_PROFILE_IMAGE_FAILED: "UPLOAD_PROFILE_IMAGE_FAILED",

  REMOVE_PROFILE_IMAGE: "REMOVE_PROFILE_IMAGE",
  REMOVE_PROFILE_IMAGE_SUCCESS: "REMOVE_PROFILE_IMAGE_SUCCESS",
  REMOVE_PROFILE_IMAGE_FAILED: "REMOVE_PROFILE_IMAGE_FAILED",

  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILED: "UPDATE_PROFILE_FAILED",
  SET_PROFILE_DETAILS: "SET_PROFILE_DETAILS",

  FETCH_CMVR_CENTERS: "FETCH_CMVR_CENTERS",
  FETCH_CMVR_CENTERS_SUCCESS: "FETCH_CMVR_CENTERS_SUCCESS",
  FETCH_CMVR_CENTERS_FAILED: "FETCH_CMVR_CENTERS_FAILED",

  ASSIGN_CMVR_CENTER: "ASSIGN_CMVR_CENTER",
  ASSIGN_CMVR_CENTER_SUCCESS: "ASSIGN_CMVR_CENTER_SUCCESS",
  ASSIGN_CMVR_CENTER_FAILED: "ASSIGN_CMVR_CENTER_FAILED",

  RESET_PROFILE_SUCCESS_STATE: "RESET_PROFILE_SUCCESS_STATE",
  RESET_CMVR_CENTER_ASSIGN_STATUS: "RESET_CMVR_CENTER_ASSIGN_STATUS",
  SET_ASSIGNED_CMVR_DETAILS: "SET_ASSIGNED_CMVR_DETAILS",

  UNASSIGN_CMVR_CENTER: "UNASSIGN_CMVR_CENTER",
  UNASSIGN_CMVR_CENTER_SUCCESS: "UNASSIGN_CMVR_CENTER_SUCCESS",
  UNASSIGN_CMVR_CENTER_FAILED: "UNASSIGN_CMVR_CENTER_FAILED",

  RESET_CMVR_CENTER_UNASSIGN_STATUS: "RESET_CMVR_CENTR_UNASSIGN_STATUS",

  ON_PROFILE_EMAIL_VERIFICATION: "ON_PROFILE_EMAIL_VERIFICATION",
  ON_PROFILE_EMAIL_VERIFICATION_SUCCESS: "ON_PROFILE_EMAIL_VERIFICATION_SUCCESS",
  ON_PROFILE_EMAIL_VERIFICATION_FAIL: "ON_PROFILE_EMAIL_VERIFICATION_FAIL",

  ON_PROFILE_DELETE: 'ON_PROFILE_DELETE',
  ON_PROFILE_DELETE_SUCCESS: 'ON_PROFILE_DELETE_SUCCESS',
  ON_PROFILE_DELETE_SUCCESS_FAIL: 'ON_PROFILE_DELETE_SUCCESS_FAIL'
};

export default Actions;
