import { takeLatest, call, put } from "redux-saga/effects";
import Api from "../../../shared/utils/Api";
// import Actions from "../../shared/store/actions";
import Actions from "./store/actions";
import Toastr from "../../../shared/utils/Toastr";

function fetchMemberTasks(stageId, memberId) {
  return Api.get(`tasks?stageId=${stageId}&memberId=${memberId}`);
}
function updateMemberTaskStatus(timelineId, data) {
  return Api.put(`account/timeline/${timelineId}/tasks`, data);
}

// function transitionProgramFetched(stepNumber) {
//   return Api.get(`account/timeline/${stepNumber}/tasks`);
// }

// function transitionTimelineTaskUpdate(timelineId, data) {
//   return Api.put(`account/timeline/${timelineId}/tasks`, data);
// }

function* watchFetchMemberTasks(data) {
  try {
    const payload = yield call(
      fetchMemberTasks,
      data.details.stageId,
      data.details.memberId
    );
    yield put({
      type: Actions.FETCH_MEMBER_TASKS_SUCCESS,
      payload,
    });
  } catch ({ errors }) {
    Toastr.error(errors.message);
    yield put({
      type: Actions.FETCH_MEMBER_TASKS_FAILED,
      data: errors,
    });
  }
}

// function* watchTimelineTaskAsync(data) {
//   try {
//     const details = yield call(transitionProgramFetched, data.stepId);
//     yield put({
//       type: Actions.ON_TIMELINE_TASK_FETCHED_SUCCESS,
//       details: details.details.taskList,
//     });
//   } catch ({ errors }) {
//     Toastr.error(errors.message);
//     yield put({
//       type: Actions.ON_TIMELINE_TASK_FETCHED_FAIL,
//       data: errors,
//     });
//   }
// }

function* watchUpdateMemberTaskStatus(data) {
  try {
    yield call(updateMemberTaskStatus, data.details.timelineId, {
      status: data.details.status,
      taskIds: data.details.taskIds,
    });
    yield put({
      type: Actions.UPDATE_MEMBER_TASK_STATUS_SUCCESS,
    });
  } catch ({ errors }) {
    Toastr.error(errors.message);
    yield put({
      type: Actions.UPDATE_MEMBER_TASK_STATUS_FAILED,
      data: errors,
    });
  }
}

const transitionProgramSteps = [
  takeLatest(Actions.FETCH_MEMBER_TASKS, watchFetchMemberTasks),
  takeLatest(Actions.UPDATE_MEMBER_TASK_STATUS, watchUpdateMemberTaskStatus),
  //   takeLatest(Actions.ON_TIMELINE_TASK_FETCHED, watchTimelineTaskAsync),
  //   takeLatest(Actions.ON_UPLOAD_TASK_STATUS, watchTimelineTaskUpdates),
];
export default transitionProgramSteps;
