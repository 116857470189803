import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../components/Loader";
import { isAuthenticated } from "./Authentication";

export function authenticateRouteRenderForHome(Component1, Component2) {
  return !isAuthenticated() ? <Component1 /> : <Component2 />;
}

// Login, forgot password, reset password
export function withoutAuthentication(Component) {
  return (props) => {
    const [loading, setIsLoading] = useState(false);
    useEffect(() => {
      if (isAuthenticated()) {
        window.location.href = "/";
      }
      setIsLoading(false);
    }, [props.match.url]);

    return loading ? <Loader /> : <Component {...props} />;
  };
}

export function withAuthentication(Component) {
  const _Component = (props) => {
    const [loading, setIsLoading] = useState(true);
    useEffect(() => {
      if (!isAuthenticated()) {
        if (props.match.url) {
          window.location.href = `/login?redirect_url=${props.match.url}`;
        } else {
          window.location.href = "/login";
        }
      }
    }, [props.match.url]);

    useEffect(() => {
      if (props.settingDetails) {
        setIsLoading(false);
      }
    }, [props.settingDetails]);

    return loading ? <Loader /> : <Component {...props} />;
  };

  const mapStateToProps = ({ setting }) => setting;
  return connect(mapStateToProps, null)(_Component);
}
