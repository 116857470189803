import { takeLatest, call, put } from "redux-saga/effects";
import queryString from "query-string";
import Api from "../../../shared/utils/Api";
import Actions from "./store/actions";

function fetchNewsApi(params) {
  let url = "news";
  if (params) {
    url = `${url}?${queryString.stringify(params)}`;
  }
  return Api.get(url);
}

function fetchNewsCategoriesApi() {
  let url = "news/categories";
  return Api.get(url);
}

function fetchNewsTagsApi() {
  let url = "news/tags";
  return Api.get(url);
}

function* watchFetchNewsAction({ data }) {
  try {
    const response = yield call(fetchNewsApi, data);
    yield put({
      type: Actions.FETCH_NEWS_SUCCESS,
      data: response.details,
    });
  } catch ({ errors }) {
    yield put({
      type: Actions.FETCH_NEWS_FAILED,
      data: errors,
    });
  }
}

function* watchFetchNewsCategoriesAction() {
  try {
    const response = yield call(fetchNewsCategoriesApi);
    yield put({
      type: Actions.FETCH_NEWS_CATEGORIES_SUCCESS,
      data: response.details,
    });
  } catch ({ errors }) {
    yield put({
      type: Actions.FETCH_NEWS_CATEGORIES_FAILED,
      data: errors,
    });
  }
}

function* watchFetchNewsTagsAction() {
  try {
    const response = yield call(fetchNewsTagsApi);
    yield put({
      type: Actions.FETCH_NEWS_TAGS_SUCCESS,
      data: response.details,
    });
  } catch ({ errors }) {
    yield put({
      type: Actions.FETCH_NEWS_TAGS_FAILED,
      data: errors,
    });
  }
}

const newsSaga = [
  takeLatest(Actions.FETCH_NEWS, watchFetchNewsAction),
  takeLatest(Actions.FETCH_NEWS_TAGS, watchFetchNewsTagsAction),
  takeLatest(Actions.FETCH_NEWS_CATEGORIES, watchFetchNewsCategoriesAction),
];
export default newsSaga;
