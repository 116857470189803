import { takeLatest, call, put } from "redux-saga/effects";
import Api from "../../../shared/utils/Api";
import Actions from "./store/actions";

function contactFormApiCall(details) {
  return Api.post("contact/request", details);
}

function contactTmApi({ id }) {
  return Api.get(`/account/command-counselorProfile/veteran/${id}`);
}

function* watchContactTmAsync(id) {
  try {
    const details = yield call(contactTmApi, id);
    yield put({ type: Actions.GET_TM_DATA_SUCCESS, details });
  } catch (error) {
    yield put({ type: Actions.GET_TM_DATA_FAIL, error });
  }
}

function* watchContactFormAsync(data) {
  try {
    yield call(contactFormApiCall, data.data);
    yield put({
      type: Actions.CONTACT_US_SUCCESS,
    });
  } catch ({ errors }) {
    yield put({
      type: Actions.CONTACT_US_FAILED,
      data: errors,
    });
  }
}

const contactSaga = [
  takeLatest(Actions.ON_CONTACT_US_FORM_SUBMIT, watchContactFormAsync),
  takeLatest(Actions.GET_TM_DATA, watchContactTmAsync),
];
export default contactSaga;
