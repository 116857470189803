import { takeLatest, call, put } from "redux-saga/effects";
import Api from "../../utils/Api";
import Actions from "./store/actions";

function fetchAdvertisementAPI(params) {
  return Api.get(`advertisements/page/${params.pageName}`);
}

function* watchFetchAdvertisementAction({ data }) {
  try {
    const response = yield call(fetchAdvertisementAPI, data);
    yield put({
      type: Actions.FETCH_ADVERTISEMENT_SUCCESS,
      data: response.details,
    });
  } catch ({ errors }) {
    yield put({
      type: Actions.FETCH_ADVERTISEMENT_FAILED,
      data: errors,
    });
  }
}

const advertisementSaga = [
  takeLatest(Actions.FETCH_ADVERTISEMENT, watchFetchAdvertisementAction),
];
export default advertisementSaga;
