import Actions from "./actions";

const defaultState = {
  fetchProfileInProgresss: false,
  fetchProfileSuccess: false,
  fetchProfileFailed: false,

  profileDetails: null,

  deleteProfile: null,
  deleteProfileSuccess: false,
  deleteProfileFail: false,
  deleteProfileLoading: false,

  uploadProfileImageInProgress: false,
  uploadProfileImageSuccess: false,
  uploadProfileImageFailed: false,

  removeProfileImageInProgress: false,
  removeProfileImageSuccess: false,
  removeProfileImageFailed: false,

  profileUuid: null,

  updateProfileInProgress: false,
  updateProfileSuccess: false,
  updateProfileFailed: false,

  fetchCMVRCentersInProgress: false,
  fetchCMVRCentersFailed: false,
  fetchCMVRCentersSuccess: false,

  assignCMVRCenterInProgress: false,
  assignCMVRCenterSuccess: false,
  assignCMVRCenterFailed: false,

  unassignCMVRCentrInProgress: false,
  unassignCMVRCenterSuccess: false,
  unassignCMVRCenterFailed: false,
  cmvrCenters: [],

  profileEmailVerificationOTP: null,
  profileEmailVerificationInProgress: false,
  profileEmailVerificationSuccess: false,
  profileEmailVerificationFailed: false,
};

function profile(state = defaultState, action) {
  switch (action.type) {
    case Actions.ON_RESET_STATE:
      return {
        ...defaultState,
      };
    case Actions.FETCH_PROFILE:
      return {
        ...state,
        fetchProfileInProgresss: true,
        fetchProfileSuccess: false,
        fetchProfileFailed: false,
      };
    case Actions.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        fetchProfileInProgresss: false,
        fetchProfileSuccess: true,
        fetchProfileFailed: false,
        profileDetails: action.details,
        profileUuid: action.details.profileUuid,
      };
    case Actions.FETCH_PROFILE_FAILED:
      return {
        ...state,
        fetchProfileInProgresss: false,
        fetchProfileFailed: true,
        fetchProfileSuccess: false,
      };
    case Actions.UPLOAD_PROFILE_IMAGE:
      return {
        ...state,
        uploadProfileImageInProgress: true,
        uploadProfileImageFailed: false,
        uploadProfileImageSuccess: false,
      };
    case Actions.UPLOAD_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        uploadProfileImageInProgress: false,
        uploadProfileImageFailed: false,
        uploadProfileImageSuccess: true,
        profileUuid: action.details.profileUuid,
      };
    case Actions.UPLOAD_PROFILE_IMAGE_FAILED:
      return {
        ...state,
        uploadProfileImageInProgress: false,
        uploadProfileImageFailed: true,
        uploadProfileImageSuccess: false,
      };
    case Actions.REMOVE_PROFILE_IMAGE:
      return {
        ...state,
        removeProfileImageInProgress: true,
        removeProfileImageSuccess: false,
        removeProfileImageFailed: false,
      };
    case Actions.REMOVE_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        removeProfileImageInProgress: false,
        removeProfileImageSuccess: true,
        removeProfileImageFailed: false,
        profileUuid: null,
      };
    case Actions.REMOVE_PROFILE_IMAGE_FAILED:
      return {
        ...state,
        removeProfileImageInProgress: false,
        removeProfileImageSuccess: false,
        removeProfileImageFailed: true,
      };
    case Actions.ON_PROFILE_DELETE:
      return {
        ...state,
        deleteProfileSuccess: false,
        deleteProfileFail: false,
        deleteProfileLoading: true,
      };
    case Actions.ON_PROFILE_DELETE_SUCCESS:
      return {
        ...state,
        deleteProfile: action.details,
        deleteProfileSuccess: true,
        deleteProfileFail: false,
        deleteProfileLoading: false,
      };
    case Actions.ON_PROFILE_DELETE_SUCCESS_FAIL:
      return {
        ...state,
        deleteProfileSuccess: false,
        deleteProfileFail: true,
        deleteProfileLoading: false,
      };
    case Actions.UPDATE_PROFILE:
      return {
        ...state,
        updateProfileInProgress: true,
        updateProfileSuccess: false,
        updateProfileFailed: false,
      };
    case Actions.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfileInProgress: false,
        updateProfileSuccess: true,
        updateProfileFailed: false,
      };
    case Actions.UPDATE_PROFILE_FAILED:
      return {
        ...state,
        updateProfileInProgress: false,
        updateProfileSuccess: false,
        updateProfileFailed: true,
      };
    case Actions.FETCH_CMVR_CENTERS:
      return {
        ...state,
        fetchCMVRCentersInProgress: true,
        fetchCMVRCentersFailed: false,
        fetchCMVRCentersSuccess: false,
      };
    case Actions.FETCH_CMVR_CENTERS_SUCCESS:
      return {
        ...state,
        fetchCMVRCentersInProgress: false,
        fetchCMVRCentersFailed: false,
        fetchCMVRCentersSuccess: true,
        cmvrCenters: action.details.details.list,
      };
    case Actions.FETCH_CMVR_CENTERS_FAILED:
      return {
        ...state,
        fetchCMVRCentersInProgress: false,
        fetchCMVRCentersFailed: true,
        fetchCMVRCentersSuccess: false,
      };
    case Actions.ASSIGN_CMVR_CENTER:
      return {
        ...state,
        assignCMVRCenterInProgress: true,
        assignCMVRCenterSuccess: false,
        assignCMVRCenterFailed: false,
      };
    case Actions.ASSIGN_CMVR_CENTER_SUCCESS:
      return {
        ...state,
        assignCMVRCenterInProgress: false,
        assignCMVRCenterSuccess: true,
        assignCMVRCenterFailed: false,
      };
    case Actions.ASSIGN_CMVR_CENTER_FAILED:
      return {
        ...state,
        assignCMVRCenterInProgress: false,
        assignCMVRCenterSuccess: false,
        assignCMVRCenterFailed: true,
      };
    case Actions.SET_PROFILE_DETAILS:
      return { ...state, profileDetails: action.data };
    case Actions.RESET_PROFILE_SUCCESS_STATE:
      return { ...state, updateProfileSuccess: false };
    case Actions.RESET_CMVR_CENTER_ASSIGN_STATUS:
      return {
        ...state,
        assignCMVRCenterInProgress: false,
        assignCMVRCenterSuccess: false,
        assignCMVRCenterFailed: false,
      };
    case Actions.SET_ASSIGNED_CMVR_DETAILS:
      const cmvrCenterId = action.details.cmvrCenterId;
      const cmvrCenterDetails = cmvrCenterId
        ? state.cmvrCenters.find(
            (cmvrCenter) => cmvrCenter.cmvrCenterId === cmvrCenterId
          )
        : null;
      return {
        ...state,
        profileDetails: {
          ...state.profileDetails,
          cmvrCenterDetails,
        },
      };
    case Actions.UNASSIGN_CMVR_CENTER:
      return {
        ...state,
        unassignCMVRCenterInProgress: true,
        unassignCMVRCenterSuccess: false,
        unassignCMVRCenterFailed: false,
      };
    case Actions.UNASSIGN_CMVR_CENTER_SUCCESS:
      return {
        ...state,
        unassignCMVRCenterInProgress: false,
        unassignCMVRCenterSuccess: true,
        unassignCMVRCenterFailed: false,
        profileDetails: {
          ...state.profileDetails,
          cmvrCenterDetails: null,
        },
      };
    case Actions.UNASSIGN_CMVR_CENTER_FAILED:
      return {
        ...state,
        unassignCMVRCenterInProgress: false,
        unassignCMVRCenterSuccess: false,
        unassignCMVRCenterFailed: true,
      };
    case Actions.RESET_CMVR_CENTER_UNASSIGN_STATUS:
      return {
        ...state,
        unassignCMVRCenterInProgress: false,
        unassignCMVRCenterSuccess: false,
        unassignCMVRCenterFailed: false,
      };
    // email verification
    case Actions.ON_PROFILE_EMAIL_VERIFICATION:
      return {
        ...state,
        profileEmailVerificationFailed: false,
        profileEmailVerificationInProgress: true,
        profileEmailVerificationSuccess: false,
      };
    case Actions.ON_PROFILE_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        profileEmailVerificationOTP: action.data.details.emailVerificationOTP,
        profileEmailVerificationFailed: false,
        profileEmailVerificationInProgress: false,
        profileEmailVerificationSuccess: true,
      };
    case Actions.ON_PROFILE_EMAIL_VERIFICATION_FAIL:
      return {
        ...state,
        profileEmailVerificationFailed: true,
        profileEmailVerificationInProgress: false,
        profileEmailVerificationSuccess: false,
      };
    default:
      return { ...state };
  }
}

export default profile;
