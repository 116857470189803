const Actions = {
  FETCH_MEMBER_TASKS: "FETCH_MEMBER_TASKS",
  FETCH_MEMBER_TASKS_SUCCESS: "FETCH_MEMBER_TASKS_SUCCESS",
  FETCH_MEMBER_TASKS_FAILED: "FETCH_MEMBER_TASKS_FAILED",
  RESET_MEMBER_TASKS_STATE: "RESET_MEMBER_TASKS_STATE",
  UPDATE_MEMBER_TASK_STATUS: "UPDATE_MEMBER_TASK_STATUS",
  UPDATE_MEMBER_TASK_STATUS_SUCCESS: "UPDATE_MEMBER_TASK_STATUS_SUCCESS",
  UPDATE_MEMBER_TASK_STATUS_FAILED: "UPDATE_MEMBER_TASK_STATUS_FAILED",
}

export default Actions;
