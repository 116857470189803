const Actions = {
  FETCH_NEWS: "FETCH_NEWS",
  FETCH_NEWS_SUCCESS: "FETCH_NEWS_SUCCESS",
  FETCH_NEWS_FAILED: "FETCH_NEWS_FAILED",

  FETCH_NEWS_CATEGORIES: "FETCH_NEWS_CATEGORIES",
  FETCH_NEWS_CATEGORIES_FAILED: "FETCH_NEWS_CATEGORIES_FAILED",
  FETCH_NEWS_CATEGORIES_SUCCESS: "FETCH_NEWS_CATEGORIES_SUCCESS",

  FETCH_NEWS_TAGS: "FETCH_NEWS_TAGS",
  FETCH_NEWS_TAGS_FAILED: "FETCH_NEWS_TAGS_FAILED",
  FETCH_NEWS_TAGS_SUCCESS: "FETCH_NEWS_TAGS_SUCCESS",
};

export default Actions;
