const Actions = {
  RESET_DASHBOARD: "RESET_DASHBOARD",

  FETCH_SETTINGS_API: "FETCH_SETTINGS_API",
  ON_SETTING_FETCHED_FAIL: "ON_SETTING_FETCHED_FAIL",
  ON_SETTING_FETCHED_SUCCESS: "ON_SETTING_FETCHED_SUCCESS",

  ON_DASHBOARD_API_FETCH: "ON_DASHBOARD_API_FETCH",
  ON_DASHBOARD_API_FETCH_SUCCESS: "ON_DASHBOARD_API_FETCH_SUCCESS",
  ON_DASHBOARD_API_FETCH_FAIL: "ON_DASHBOARD_API_FETCH_FAIL",

  ON_FEEDBACK_DATA_FETCH: "ON_FEEDBACK_DATA_FETCH",
  ON_FEEDBACK_DATA_FETCH_SUCCESS: "ON_FEEDBACK_DATA_FETCH_SUCCESS",
  ON_FEEDBACK_DATA_FETCH_FAIL: "ON_FEEDBACK_DATA_FETCH_FAIL",

  UPDATE_PROFILE_EAOS_DATE: "UPDATE_PROFILE_EAOS_DATE",
  UPDATE_PROFILE_EAOS_DATE_SUCCESS: "UPDATE_PROFILE_EAOS_DATE_SUCCESS",
  UPDATE_PROFILE_EAOS_DATE_FAILED: "UPDATE_PROFILE_EAOS_DATE_FAILED",
};

export default Actions;
