import { takeLatest, call, put } from "redux-saga/effects";
import Api from "../../../shared/utils/Api";
import Actions from "../../shared/store/actions";
import Toastr from "../../../shared/utils/Toastr";

function transistionTimelineList(isSpouseChecklist) {
  return Api.get(`timeline?isSpouseChecklist=${isSpouseChecklist}`);
}

function* watchTimelineTaskListCallAsync(data) {
  try {
    let details = yield call(transistionTimelineList, data.isSpouseChecklist);
    yield put({
      type: Actions.ON_TIMELINE_STEPS_FETCH_SUCCESS,
      details: details.details.timelineList,
    });
  } catch ({ errors }) {
    Toastr.error(errors.message);
    yield put({
      type: Actions.ON_TIMELINE_STEPS_FETCH_FAIL,
      data: errors,
    });
  }
}

const transitionList = [
  takeLatest(Actions.ON_TIMELINE_STEPS_FETCH, watchTimelineTaskListCallAsync),
];
export default transitionList;
