import Actions from './actions';

const defaultState = {
    timelineResourceApiFetch: false,
    timelineResourceApiFetchFail: false,
    timelineResourceApiFetchSuccess: false,

    categoryDetails: null,
    categoryImageUrl: null,
    categoryResourceList: []
}

function getImageUrlBySlug(value) {
    switch (value) {
        case "career-financial-resources":
            return "icon-career";
        case "education-resources":
            return "icon-Education";
        case "health-and-wellbeing":
            return "icon-Health";
        case "additional-resources":
            return "icon-circle-with-plus";
        default:
            break;
    }
}

export default function TimelineResourceReducer(state = defaultState, action) {
    switch (action.type) {
        //transition resource
        case Actions.ON_TIMELINE_RESOURCE_API_FETCH:
            let imageUrl = getImageUrlBySlug(action.data);

            return {
                ...state,
                categoryImageUrl: imageUrl,
                timelineResourceApiFetch: true,
                timelineResourceApiFetchFail: false,
                timelineResourceApiFetchSuccess: false
            };
        case Actions.ON_TIMELINE_RESOURCE_API_FETCH_FAIL:
            return {
                ...state,
                timelineResourceApiFetch: false,
                timelineResourceApiFetchFail: true,
                timelineResourceApiFetchSuccess: false
            };
        case Actions.ON_TIMELINE_RESOURCE_API_FETCH_SUCCESS:
            return {
                ...state,
                timelineResourceApiFetch: false,
                timelineResourceApiFetchFail: false,
                timelineResourceApiFetchSuccess: true,
                categoryResourceList: action.data
                    ? action.data.categoryResourceList : [],
                categoryDetails: action.data
                    ? action.data.categoryDetails : []
            };
        default:
            return state;
    }
}