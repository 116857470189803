import { ActionType } from "../../../../../shared/types/redux";
import {
  TimelineListReducerActionDetailsType,
  TimelineListReducerStateType,
} from "../type";
import Actions from "./actions";

const defaultState: TimelineListReducerStateType = {
  fetchTimelineListInProgress: false,
  fetchTimelineListSuccess: false,
  fetchTimelineListFailed: false,
  isSpouseChecklist: null,
  timelineList: [],
};
const reducer = (
  state = defaultState,
  action: ActionType<TimelineListReducerActionDetailsType>
) => {
  switch (action.type) {
    case Actions.FETCH_TIMELINE_LIST:
      return {
        ...state,
        fetchTimelineListInProgress: true,
        fetchTimelineListSuccess: false,
        fetchTimelineListFailed: false,
      };
    case Actions.FETCH_TIMELINE_LIST_SUCCESS:
      return {
        ...state,
        fetchTimelineListInProgress: false,
        fetchTimelineListSuccess: true,
        fetchTimelineListFailed: false,
        isSpouseChecklist: action.payload.details.isSpouseChecklist,
        timelineList: action.payload.details.timelineList,
      };
    case Actions.FETCH_TIMELINE_LIST_FAILED:
      return {
        ...state,
        fetchTimelineListInProgress: false,
        fetchTimelineListSuccess: false,
        fetchTimelineListFailed: true,
        isSpouseChecklist: null,
      };
    default:
      return { ...state };
  }
};
export default reducer;
