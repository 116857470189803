import Actions from "./actions";

export function resetDashboard() {
  return {
    type: Actions.RESET_DASHBOARD,
  };
}

export function fetchSettingsApi() {
  return {
    type: Actions.FETCH_SETTINGS_API,
  };
}

export function onDashboardApiCall() {
  return {
    type: Actions.ON_DASHBOARD_API_FETCH,
  };
}

export function onFeedbackApiCall() {
  return {
    type: Actions.ON_FEEDBACK_DATA_FETCH,
  };
}

export function updateEAOSDate(data) {
  return {
    type: Actions.UPDATE_PROFILE_EAOS_DATE,
    data,
  };
}
