import { Col, Spinner } from "reactstrap";
import "./index.scss";

export default function Loader({ color = "primary" }) {
  return (
    <Col className="d-flex justify-content-center align-items-center application-loader">
      <Spinner
        style={{
          width: "4rem",
          height: "4rem",
        }}
        color={color}
      />
    </Col>
  );
}
