import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "bootstrap/dist/css/bootstrap.css";
import "react-intl-tel-input/dist/main.css";
import rootSaga from "./saga";
import Reducers from "./reducers";
import createSagaMiddleware from "redux-saga";
import { combineReducers, createStore, applyMiddleware } from "redux";
import "./index.scss";

const reducers = combineReducers(Reducers);
const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducers, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
