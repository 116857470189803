import Actions from './action';

const defaultState = {
    resetPasswordFetch: false,
    resetPasswordFetchedSuccess: false,
    resetPasswordFetchedFail: false,
    resetPasswordDetails: {}
}
export default function resetPasswordReducer(state = defaultState, action) {
    switch (action.type) {
        case Actions.UPDATE_RESET_PASSWORD_FETCHED:
            return {
                ...state,
                resetPasswordFetch: true,
                resetPasswordFetchedSuccess: false,
                resetPasswordFetchedFail: false,
            }
        case Actions.UPDATE_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordFetch: false,
                resetPasswordFetchedSuccess: true,
                resetPasswordFetchedFail: false,
                resetPasswordDetails: action.details
            }
        case Actions.UPDATE_RESET_PASSWORD_FAIL:
            return {
                resetPasswordFetch: false,
                resetPasswordFetchedSuccess: false,
                resetPasswordFetchedFail: true,
                resetPasswordDetails: action.details
            }
        default:
            return state;
    }
}