const Actions = {
  ON_CONTACT_US_FORM_SUBMIT: "ON_CONTACT_US_FORM_SUBMIT",
  CONTACT_US_SUCCESS: "CONTACT_US_SUCCESS",
  CONTACT_US_FAILED: "CONTACT_US_FAILED",
  RESET_CONTACT_STATE: "RESET_CONTACT_STATE",

  GET_TM_DATA: "GET_TM_DATA",
  GET_TM_DATA_SUCCESS: "GET_TM_DATA_SUCCESS",
  GET_TM_DATA_FAIL: "GET_TM_DATA_FAIL",
};

export default Actions;
