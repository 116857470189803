import { reducer as formReducer } from "redux-form";
import contactReducer from "./screens/components/Contact/store/reducer";
import changePasswordReducer from "./screens/components/ChangePassword/store/reducer";
import newsReducer from "./screens/components/News/store/reducer";
import advertisementReducer from "./shared/components/Advertisement/store/reducer";
import settingReducer from "./shared/store/reducers";
import dashboardReducer from "./screens/shared/store/reducers";
import homeReducer from "./screens/components/Home/store/reducers";
import loginReducer from "./screens/components/Login/store/reducers";
import signUpReducer from "./screens/components/SignUp/store/reducers";
import sourceReducer from "./screens/shared/components/UserRegisterationSourceModal/store/reducer";
import resetPasswordReducer from "./screens/components/ResetPassword/store/reducers";
import forgotPasswordReducer from "./screens/components/ForgotPassword/store/reducers";
import timelineResourceReducer from "./screens/components/TimelineResources/store/reducers";
import profileReducer from "./screens/components/Profile/store/reducer";
import memberTasksReducer from "./screens/components/TransitionProgramSteps/store/reducer";
import timelineListReducer from "./screens/shared/components/TimelineList/store/reducer";
import weeklyNewsletterUnsubscribeReducer from "./screens/components/WeeklyNewsletterUnsubscribe/store/reducer";
import employerReducer from "./screens/components/VeteranEmployers/store/reducer";
import deleteReducer from "./screens/components/DeleteAccount/store/reducer";

const reducers = {
  form: formReducer,
  home: homeReducer,
  login: loginReducer,
  signUp: signUpReducer,
  setting: settingReducer,
  dashboard: dashboardReducer,
  resetPassword: resetPasswordReducer,
  forgotPassword: forgotPasswordReducer,
  timelineResource: timelineResourceReducer,
  contact: contactReducer,
  changePassword: changePasswordReducer,
  news: newsReducer,
  advertisement: advertisementReducer,
  profile: profileReducer,
  source: sourceReducer,
  memberTasks: memberTasksReducer,
  timelineList: timelineListReducer,
  weeklkyNewsletterUnsubscribe: weeklyNewsletterUnsubscribeReducer,
  employer: employerReducer,
  del: deleteReducer
};

export default reducers;
