import Actions from "./actions";

const defaultState = {
  deleteAccountProgress: false,
  deleteAccountSuccess: false,
  deleteAccountFailed: false,

  deleteAccountProgressId: false,
  deleteAccountSuccessId: false,
  deleteAccountFailedId: false,

  deactivateAccountProgress: false,
  deactivateAccountSuccess: false,
  deactivateAccountFailed: false,

  deactivateAccountProgressId: false,
  deactivateAccountSuccessId: false,
  deactivateAccountFailedId: false,
};

const deleteReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Actions.DELETE_ACCOUNT:
      return {
        ...state,
        deleteAccountProgress: true,
        deleteAccountSuccess: false,
        deleteAccountFailed: false,
      };
    case Actions.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteAccountProgress: false,
        deleteAccountSuccess: true,
        deleteAccountFailed: false,
      };
    case Actions.DELETE_ACCOUNT_FAIL:
      return {
        ...state,
        deleteAccountProgress: false,
        deleteAccountSuccess: false,
        deleteAccountFailed: true,
      };

    case Actions.DELETE_ACCOUNT_UUID:
      return {
        ...state,
        deleteAccountProgressId: true,
        deleteAccountSuccessId: false,
        deleteAccountFailedId: false,
      };
    case Actions.DELETE_ACCOUNT_SUCCESS_UUID:
      return {
        ...state,
        deleteAccountProgressId: false,
        deleteAccountSuccessId: true,
        deleteAccountFailedId: false,
      };
    case Actions.DELETE_ACCOUNT_FAIL_UUID:
      return {
        ...state,
        deleteAccountProgressId: false,
        deleteAccountSuccessId: false,
        deleteAccountFailedId: true,
      };
    case Actions.DEACTIVATE_ACCOUNT:
      return {
        ...state,
        deactivateAccountProgress: true,
        deactivateAccountSuccess: false,
        deactivateAccountFailed: false,
      };
    case Actions.DEACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deactivateAccountProgress: false,
        deactivateAccountSuccess: true,
        deactivateAccountFailed: false,
      };
    case Actions.DEACTIVATE_ACCOUNT_FAIL:
      return {
        ...state,
        deactivateAccountProgress: false,
        deactivateAccountSuccess: false,
        deactivateAccountFailed: true,
      };
    case Actions.DEACTIVATE_ACCOUNT_UUID:
      return {
        ...state,
        deactivateAccountProgressId: true,
        deactivateAccountSuccessId: false,
        deactivateAccountFailedId: false,
      };
    case Actions.DEACTIVATE_ACCOUNT_SUCCESS_UUID:
      return {
        ...state,
        deactivateAccountProgressId: false,
        deactivateAccountSuccessId: true,
        deactivateAccountFailedId: false,
      };
    case Actions.DEACTIVATE_ACCOUNT_FAIL_UUID:
      return {
        ...state,
        deactivateAccountProgressId: false,
        deactivateAccountSuccessId: false,
        deactivateAccountFailedId: true,
      };
    default:
      return { ...state };
  }
};

export default deleteReducer;
