import { takeLatest, call, put } from "redux-saga/effects";
import Actions from "./store/action";
import Api from "../../../shared/utils/Api";
import Toastr from "../../../shared/utils/Toastr";

function loginApiCall(details) {
    return Api.post("account/forgot-password", details);
}

function* watchForgotPasswordAsync(data) {
    try {
        const details = yield call(loginApiCall, data.data);
        yield put({
            type: Actions.UPDATE_FORGOT_PASSWORD_SUCCESS,
            details
        });
        Toastr.success(`Forgot password!!!`);
    } catch ({ errors }) {
        yield put({
            type: Actions.UPDATE_FORGOT_PASSWORD_FAIL,
            data: errors
        });
    }
}

const forgotPasswordSaga = [takeLatest(Actions.UPDATE_FORGOT_PASSWORD_FETCHED, watchForgotPasswordAsync)]
export default forgotPasswordSaga;


