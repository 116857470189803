const Actions = {
  ON_RESET_STATE: "ON_RESET_STATE",

  UPDATE_LOGIN_FETCH: "UPDATE_LOGIN_FETCH",
  UPDATE_LOGIN_FORM_SUCCESS: "UPDATE_LOGIN_FORM_SUCCESS",
  UPDATE_LOGIN_FORM_FAIL: "UPDATE_LOGIN_FORM_FAIL",
  ON_RESET_LOGIN: "ON_RESET_LOGIN",

  GET_LOGIN_FETCH: "GET_LOGIN_FETCH",
  GET_LOGIN_FETCH_SUCCESS: " GET_LOGIN_FETCH_SUCCESS",
  GET_LOGIN_FETCH_FAIL: "GET_LOGIN_FETCH_FAIL",
};

export default Actions;
