import Actions from "./actions";

const defaultState = {
  fetchAdvertisementInProgress: false,
  fetchAdvertisementSuccess: false,
  fetchAdvertisementFailed: false,
  advertisements: [],
};

function advertisement(state = defaultState, action) {
  switch (action.type) {
    case Actions.FETCH_ADVERTISEMENT:
      return {
        ...state,
        fetchAdvertisementInProgress: true,
        fetchAdvertisementSuccess: false,
        fetchAdvertisementFailed: false,
        advertisements: [],
      };
    case Actions.FETCH_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        fetchAdvertisementInProgress: false,
        fetchAdvertisementSuccess: true,
        fetchAdvertisementFailed: false,
        advertisements: action.data.advertisementList,
      };
    case Actions.FETCH_ADVERTISEMENT_FAILED:
      return {
        ...state,
        fetchAdvertisementInProgress: false,
        fetchAdvertisementSuccess: false,
        fetchAdvertisementFailed: true,
      };
    default:
      return { ...state };
  }
}

export default advertisement;
