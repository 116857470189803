import { takeLatest, call, put } from "redux-saga/effects";
import Actions from "./store/actions";
import Api from "../../../shared/utils/Api";
import Toastr from "../../../shared/utils/Toastr";

function timelineResourceApiCall(slug) {
    return Api.get(`resource/${slug}`);
}

function* watchTimelineResourcesApiSync(data) {
    try {
        const res = yield call(timelineResourceApiCall, data.data);
        yield put({
            type: Actions.ON_TIMELINE_RESOURCE_API_FETCH_SUCCESS,
            data: res.details
        });
    } catch ({ errors }) {
        Toastr.error(errors.message);
        yield put({
            type: Actions.ON_TIMELINE_RESOURCE_API_FETCH_FAIL,
            data: errors.message
        });
    }
}

const timelineResourcesSaga = [
    takeLatest(Actions.ON_TIMELINE_RESOURCE_API_FETCH, watchTimelineResourcesApiSync),
]
export default timelineResourcesSaga;


