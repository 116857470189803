import { takeLatest, call, put } from "redux-saga/effects";
import Actions from "./store/actions";
import Api from "../../../shared/utils/Api";
import Toastr from "../../../shared/utils/Toastr";

function loginApiCall(details) {
  return Api.post("account/login", details);
}

function getLoginDataApiCall(details) {
  return Api.get("account/login", details);
}

function* watchLoginAsync(data) {
  try {
    const details = yield call(loginApiCall, data.data);
    yield put({
      type: Actions.UPDATE_LOGIN_FORM_SUCCESS,
      details,
    });
    Toastr.success(`Login successful !!!`);
  } catch ({ errors }) {
    Toastr.error(errors.message);
    yield put({
      type: Actions.ON_RESET_STATE,
    });
    yield put({
      type: Actions.UPDATE_LOGIN_FORM_FAIL,
      data: errors,
    });
  }
}

function* watchGetLoginAsync(data) {
  try {
    const details = yield call(getLoginDataApiCall, data.data);
    yield put({
      type: Actions.GET_LOGIN_FETCH_SUCCESS,
      details,
    });
  } catch ({ errors }) {
    yield put({
      type: Actions.GET_LOGIN_FETCH_FAIL,
      data: errors,
    });
  }
}

const loginSaga = [
  takeLatest(Actions.UPDATE_LOGIN_FETCH, watchLoginAsync),
  takeLatest(Actions.GET_LOGIN_FETCH, watchGetLoginAsync),
];
export default loginSaga;
