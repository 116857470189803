import Actions from "./actions";

const defaultState = {
  sourceList: [],
  fetchSourceListInProgress: false,
  fetchSourceListSuccess: false,
  fetchSourceListFailed: false,
};
const sourceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Actions.FETCH_SOURCE_LIST:
      return {
        ...state,
        fetchSourceListInProgress: true,
        fetchSourceListSuccess: false,
        fetchSourceListFailed: false,
      };
    case Actions.FETCH_SOURCE_LIST_SUCCESS:
      return {
        ...state,
        fetchSourceListInProgress: false,
        fetchSourceListSuccess: true,
        fetchSourceListFailed: false,
        sourceList: action.payload.details,
      };
    case Actions.FETCH_SOURCE_LIST_FAILED:
      return {
        ...state,
        fetchSourceListInProgress: false,
        fetchSourceListSuccess: false,
        fetchSourceListFailed: true,
      };
    default:
      return { ...state };
  }
};
export default sourceReducer;
