import { takeLatest, call, put } from "redux-saga/effects";
import Api from "../../../shared/utils/Api";
import Actions from "../../shared/store/actions";
import Toastr from "../../../shared/utils/Toastr";

function statementOfBenefitApiCall() {
    return Api.get(`statement-of-benefits`);
}

function* watchStatementOfBenefitApiSync() {
    try {
        const res = yield call(statementOfBenefitApiCall);
        yield put({
            type: Actions.ON_STATEMENT_OF_BENEFITS_API_FETCH_SUCCESS,
            data: res.details
        });
    } catch ({ errors }) {
        Toastr.error(errors.message);
        yield put({
            type: Actions.ON_STATEMENT_OF_BENEFITS_API_FETCH_FAIL
        });
    }
}

const statementOfBenefitSaga = [
    takeLatest(Actions.ON_STATEMENT_OF_BENEFITS_API_FETCH, watchStatementOfBenefitApiSync),
]
export default statementOfBenefitSaga;


