import { takeLatest, call, put } from "redux-saga/effects";
import Cookies from "universal-cookie";
import Api from "../utils/Api";
import Toastr from "../utils/Toastr";
import Actions from "./actions";

function eaosDateUpdate(data) {
  return Api.put("/account/eos-date", data);
}

function settingApiCall() {
  return Api.get("settings");
}

function dashboardApiCall() {
  return Api.get("account/dashboard");
}

function feedbackApiCall() {
  return Api.get("referred-sources/list");
}

function* watchEAOSDateUpdate(data) {
  try {
    yield call(eaosDateUpdate, data.data);
    const cookies = new Cookies();
    cookies.set("easoDate", data.data.eosDate);
    Toastr.success("EAOS Date Update Successfully");
    yield put({
      type: Actions.UPDATE_PROFILE_EAOS_DATE_SUCCESS,
      data,
    });
  } catch ({ errors }) {
    Toastr.error(errors.message);
    yield put({
      type: Actions.UPDATE_PROFILE_EAOS_DATE_FAILED,
      data: errors,
    });
  }
}

function* watchSettingAsync() {
  try {
    const details = yield call(settingApiCall);
    yield put({
      type: Actions.ON_SETTING_FETCHED_SUCCESS,
      details: details.details,
    });
  } catch ({ errors }) {
    Toastr.error(errors.message);
    yield put({
      type: Actions.ON_SETTING_FETCHED_FAIL,
      data: errors,
    });
  }
}

function* watchDashboardAsync() {
  try {
    const details = yield call(dashboardApiCall);
    yield put({
      type: Actions.ON_DASHBOARD_API_FETCH_SUCCESS,
      details: details.details,
    });
  } catch ({ errors }) {
    Toastr.error(errors.message);
    yield put({
      type: Actions.ON_DASHBOARD_API_FETCH_FAIL,
      data: errors,
    });
  }
}

function* watchFeedbackAsync() {
  try {
    const details = yield call(feedbackApiCall);
    yield put({
      type: Actions.ON_FEEDBACK_DATA_FETCH_SUCCESS,
      details: details.details,
    });
  } catch ({ errors }) {
    Toastr.error(errors.message);
    yield put({
      type: Actions.ON_FEEDBACK_DATA_FETCH_FAIL,
      data: errors,
    });
  }
}

const settingSaga = [
  takeLatest(Actions.UPDATE_PROFILE_EAOS_DATE, watchEAOSDateUpdate),
  takeLatest(Actions.FETCH_SETTINGS_API, watchSettingAsync),
  takeLatest(Actions.ON_DASHBOARD_API_FETCH, watchDashboardAsync),
  takeLatest(Actions.ON_FEEDBACK_DATA_FETCH, watchFeedbackAsync),
];
export default settingSaga;
