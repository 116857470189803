import Actions from "./actions";

const defaultState = {
  contactUsInProgress: false,
  contactUsSuccess: false,
  contactUsFailed: false,
  contactUsError: null,

  tmDataInProgress: false,
  tmDataSuccess: false,
  tmDataFail: false,
  tmData: null,
};

function contact(state = defaultState, action) {
  switch (action.type) {
    case Actions.ON_CONTACT_US_FORM_SUBMIT:
      return {
        ...state,
        contactUsInProgress: true,
        contactUsSuccess: false,
        contactUsFailed: false,
      };
    case Actions.CONTACT_US_SUCCESS:
      return {
        ...state,
        contactUsInProgress: false,
        contactUsSuccess: true,
        contactUsFailed: false,
      };
    case Actions.CONTACT_US_FAILED:
      return {
        ...state,
        contactUsInProgress: false,
        contactUsSuccess: false,
        contactUsFailed: true,
        contactUsError: action.data,
      };

    case Actions.GET_TM_DATA:
      return {
        ...state,
        tmDataInProgress: true,
        tmDataSuccess: false,
        tmDataFail: false,
      };

    case Actions.GET_TM_DATA_SUCCESS:
      return {
        ...state,
        tmDataInProgress: false,
        tmDataSuccess: false,
        tmDataFail: false,
        tmData: action.details,
      };

    case Actions.GET_TM_DATA_FAIL:
      return {
        ...state,
        tmDataInProgress: false,
        tmDataSuccess: false,
        tmDataFail: true,
      };
    case Actions.RESET_CONTACT_STATE:
      return { ...defaultState };
    default:
      return { ...state };
  }
}

export default contact;
