import { ActionType } from "../../../../shared/types/redux";
import { MemberTasksReducerStateType, Tasks } from "../type";
import Actions from "./actions";

const defaultState: MemberTasksReducerStateType = {
  fetchMemberTasksInProgress: false,
  fetchMemberTasksFailed: false,
  fetchMemberTasksSuccess: false,
  updateMemberTaskStatusInProgress: false,
  updateMemberTaskStatusFailed: false,
  updateMemberTaskStatusSuccess: false,
  timelineDisplayName: null,
  timelineId: null,
  tasks: [],
};
const reducer = (state = defaultState, action: ActionType<Tasks>) => {
  switch (action.type) {
    case Actions.FETCH_MEMBER_TASKS:
      return {
        ...state,
        fetchMemberTasksInProgress: true,
        fetchMemberTasksFailed: false,
        fetchMemberTasksSuccess: false,
      };
    case Actions.FETCH_MEMBER_TASKS_SUCCESS:
      return {
        ...state,
        fetchMemberTasksInProgress: false,
        fetchMemberTasksFailed: false,
        fetchMemberTasksSuccess: true,
        timelineDisplayName: action.payload.details.timelineDisplayName,
        timelineId: action.payload.details.timelineId,
        tasks: action.payload.details.taskList,
      };
    case Actions.FETCH_MEMBER_TASKS_FAILED:
      return {
        ...state,
        fetchMemberTasksInProgress: false,
        fetchMemberTasksFailed: true,
        fetchMemberTasksSuccess: false,
      };
    case Actions.UPDATE_MEMBER_TASK_STATUS:
      return {
        ...state,
        updateMemberTaskStatusInProgress: true,
        updateMemberTaskStatusFailed: false,
        updateMemberTaskStatusSuccess: false,
      };
    case Actions.UPDATE_MEMBER_TASK_STATUS_SUCCESS:
      return {
        ...state,
        updateMemberTaskStatusInProgress: false,
        updateMemberTaskStatusFailed: false,
        updateMemberTaskStatusSuccess: true,
      };
    case Actions.UPDATE_MEMBER_TASK_STATUS_FAILED:
      return {
        ...state,
        updateMemberTaskStatusInProgress: false,
        updateMemberTaskStatusFailed: true,
        updateMemberTaskStatusSuccess: false,
      };
    case Actions.RESET_MEMBER_TASKS_STATE:
      return defaultState;
    default:
      return { ...state };
  }
};
export default reducer;
