import { takeLatest, call, put } from "redux-saga/effects";
import Actions from "./store/actions";
import Api from "../../../shared/utils/Api";
import Toastr from "../../../shared/utils/Toastr";

function homeApiCall() {
    return Api.get("home");
}

function* watchHomeApiAsync() {
    try {
        const details = yield call(homeApiCall);
        yield put({
            type: Actions.ON_HOME_API_FETCHED_SUCCESS,
            details: details.details
        });
    } catch ({ errors }) {
        Toastr.error(errors.message);
        yield put({
            type: Actions.ON_HOME_API_FETCHED_FAIL,
            data: errors.message
        });
    }
}

const homeSaga = [takeLatest(Actions.ON_HOME_API_FETCHED, watchHomeApiAsync)]
export default homeSaga;


