const Actions = {
  ON_RESET_STATE: "ON_RESET_STATE",
  
  ON_EMAIL_VERIFICATION: "ON_EMAIL_VERIFICATION",
  ON_EMAIL_VERIFICATION_SUCCESS: "ON_EMAIL_VERIFICATION_SUCCESS",
  ON_EMAIL_VERIFICATION_FAIL: "ON_EMAIL_VERIFICATION_FAIL",

  ON_UPDATE_CANDIDATE_NAME: "ON_UPDATE_CANDIDATE_NAME",
  ON_UPDATE_CANDIDATE_EMAIL: "ON_UPDATE_CANDIDATE_EMAIL",
  ON_UPDATE_CANDIDATE_PASSWORD: "ON_UPDATE_CANDIDATE_PASSWORD",
  ON_UPDATE_CANDIDATE_EOS_DATE: "ON_UPDATE_CANDIDATE_EOS_DATE",
  ON_UPDATE_CANDIDATE_RE_ENTER_PASSWORD:
    "ON_UPDATE_CANDIDATE_RE_ENTER_PASSWORD",

  ON_SIGN_UP: "ON_SIGN_UP",
  ON_SIGN_UP_SUCCESS: "ON_SIGN_UP_SUCCESS",
  ON_SIGN_UP_FAIL: "ON_SIGN_UP_FAIL",

  FETCH_NAME_OF_COMMAND: "FETCH_NAME_OF_COMMAND",
  FETCH_NAME_OF_COMMAND_SUCCESS: "FETCH_NAME_OF_COMMAND_SUCCESS",
  FETCH_NAME_OF_COMMAND_SUCCESS_FAILED: "FETCH_NAME_OF_COMMAND_SUCCESS_FAILED",
  
  ON_UPDATE_CANDIDATE_DUTY_LOCATION: "ON_UPDATE_CANDIDATE_DUTY_LOCATION",
  ON_UPDATE_CANDIDATE_PREFERRED_LOCATION:
    "ON_UPDATE_CANDIDATE_PREFERRED_LOCATION",
};
export default Actions;
