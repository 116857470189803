import Actions from "../store/actions";
import API from "../../../../shared/utils/Api";
import { call, put, takeLatest } from "redux-saga/effects";

function getCitiesCall() {
  return API.get("/employeeDirectory/cities");
}

function getIndustryByCityCall({ id }) {
  return API.get(`/employeeDirectory/industry/${id}`);
}

function getIndustryCityBothCall({ cityID, industryID }) {
  return API.get(
    `/employeeDirectory/?city_id=${cityID}&industry_id=${industryID}`
  );
}

function getCompanyDetailsCall({ id }) {
  return API.get(`/employeeDirectory/${id}`);
}

function* watchGetCitiesAsync() {
  try {
    const details = yield call(getCitiesCall);
    yield put({
      type: Actions.GET_CITIES_SUCCESS,
      details,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_CITIES_FAIL,
      error,
    });
  }
}

function* watchGetIndustryByCityAsync(data) {
  try {
    const details = yield call(getIndustryByCityCall, data);
    yield put({
      type: Actions.GET_INDUSTRY_BY_CITY_SUCCESS,
      details,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_INDUSTRY_BY_CITY_FAIL,
      error,
    });
  }
}

function* watchGetCompanyDetailsAsync(data) {
  try {
    const details = yield call(getCompanyDetailsCall, data);
    yield put({
      type: Actions.GET_COMPANY_DETAILS_SUCCESS,
      details,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_COMPANY_DETAILS_FAIL,
      error,
    });
  }
}
function* watchGetIndustryCityBothAsync(data) {
  try {
    const details = yield call(getIndustryCityBothCall, data);
    yield put({
      type: Actions.GET_INDUSTRY_ID_BOTH_SUCCESS,
      details,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_INDUSTRY_ID_BOTH_FAIL,
      error,
    });
  }
}

const employerSaga = [
  takeLatest(Actions.GET_CITIES, watchGetCitiesAsync),
  takeLatest(Actions.GET_INDUSTRY_BY_CITY, watchGetIndustryByCityAsync),
  takeLatest(Actions.GET_INDUSTRY_ID_BOTH, watchGetIndustryCityBothAsync),
  takeLatest(Actions.GET_COMPANY_DETAILS, watchGetCompanyDetailsAsync),
];

export default employerSaga;
