import Actions from "./actions";

const defaultState = {
  timelineTaskQuestionsList: null,
  profileDropdownList: [
    {
      id: 1,
      label: "Branch of Service",
    },
    {
      id: 2,
      label: "Status",
    },
    {
      id: 3,
      label: "Rank",
    },
    {
      id: 4,
      label: "Military Occupation",
    },
  ],
  selectedFeedbackOption: {
    source: "linkedIn",
    description: "Test",
  },
  headerTitle: "Managing Your Transition Timeline (MYTT)",
  isCardVisible: true,
  headerBgColor: "dark",
  goToNextStatus: null,

  timelineTaskListFetched: false,
  timelineTaskListFetchedSuccess: false,
  timelineTaskListFetchedFail: false,
  timelineStepsTaskList: null,

  statementOfBenefitsDetails: null,
  statementOfBenefitsFetch: false,
  statementOfBenefitsFetchFail: false,
  statementOfBenefitsFetchSuccess: false,
};

function updateProgramStepsCheckboxValue(currenList, stepId, selectedId) {
  let selectedStepList = [...currenList].map((value) => {
    if (parseInt(value.stepId) === parseInt(stepId)) {
      let count = 0;
      value.stepsList.map((data) => {
        if (data.id === selectedId) {
          data.checked = !data.checked;
        }
        data.checked && count++;
        return data;
      });
      value.stepsList.length === count
        ? (value.allStepsChecked = true)
        : (value.allStepsChecked = false);
    }
    return value;
  });
  return selectedStepList;
}

export default function DashboardReducer(state = defaultState, action) {
  switch (action.type) {
    case Actions.ON_RESET_STATE:
      return {
        ...defaultState,
      };
    case Actions.ON_ROUTE_CHANGE:
      return {
        ...state,
        HeaderTitle: action.title,
        headerBgColor: action.bgTheam,
        IsCardVisible: action.cardVisibility,
      };
    case Actions.ON_UPDATE_CHECKBOX_VALUE:
      let modifiedProgramSteps = updateProgramStepsCheckboxValue(
        state.programSteps,
        action.stepId,
        action.selectedId
      );

      return {
        ...state,
        programSteps: [...modifiedProgramSteps],
      };
    //timeline
    case Actions.ON_TIMELINE_STEPS_FETCH:
      return {
        ...state,
        timelineTaskListFetched: true,
        timelineTaskListFetchedSuccess: false,
        timelineTaskListFetchedFail: false,
      };
    case Actions.ON_TIMELINE_STEPS_FETCH_SUCCESS:
      let modifiedTimelineList = action.details.map((value, index) => {
        value.sortOrder = index + 1;
        return value;
      });
      return {
        ...state,
        timelineTaskListFetched: false,
        timelineTaskListFetchedSuccess: true,
        timelineTaskListFetchedFail: false,
        timelineStepsTaskList: [...modifiedTimelineList],
      };
    case Actions.ON_TIMELINE_STEPS_FETCH_FAIL:
      return {
        ...state,
        timelineTaskListFetched: false,
        timelineTaskListFetchedSuccess: false,
        timelineTaskListFetchedFail: true,
      };
    //statement of benefits
    case Actions.ON_STATEMENT_OF_BENEFITS_API_FETCH:
      return {
        ...state,
        statementOfBenefitsFetch: true,
        statementOfBenefitsFetchFail: false,
        statementOfBenefitsFetchSuccess: false,
      };
    case Actions.ON_STATEMENT_OF_BENEFITS_API_FETCH_FAIL:
      return {
        ...state,
        statementOfBenefitsFetch: false,
        statementOfBenefitsFetchFail: true,
        statementOfBenefitsFetchSuccess: false,
      };
    case Actions.ON_STATEMENT_OF_BENEFITS_API_FETCH_SUCCESS:
      return {
        ...state,
        statementOfBenefitsFetch: false,
        statementOfBenefitsFetchFail: false,
        statementOfBenefitsFetchSuccess: true,
        statementOfBenefitsDetails: action.data
          ? action.data.statementOfBenefitDetails
          : null,
      };
    // feedback option change
    case Actions.ON_FEEDBACK_OPTION_CHANGE:
      return {
        ...state,
        selectedFeedbackOption: {
          source: action.data.source,
          description: action.data.description,
        }
      };
    default:
      return state;
  }
}
