import { takeLatest, call, put } from "redux-saga/effects";
import Api from "../../../../shared/utils/Api";
import Actions from "./store/actions";
import Toastr from "../../../../shared/utils/Toastr";

function fetchSourceList() {
  return Api.get("referred-sources/list");
}

function* watchFetchSourceList() {
  try {
    const payload = yield call(fetchSourceList());
    yield put({
      type: Actions.FETCH_SOURCE_LIST_SUCCESS,
      payload,
    });
  } catch ({ errors }) {
    Toastr.error(errors.message);
    yield put({
      type: Actions.FETCH_SOURCE_LIST_FAILED,
      data: errors,
    });
  }
}

const sourceSaga = [
  takeLatest(Actions.FETCH_SOURCE_LIST, watchFetchSourceList),
];

export default sourceSaga;
