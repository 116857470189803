const Actions = {
    DELETE_ACCOUNT: 'DELETE_ACCOUNT',
    DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
    DELETE_ACCOUNT_FAIL: 'DELETE_ACCOUNT_FAIL',

    DELETE_ACCOUNT_UUID: 'DELETE_ACCOUNT_UUID',
    DELETE_ACCOUNT_SUCCESS_UUID: 'DELETE_ACCOUNT_SUCCESS_UUID',
    DELETE_ACCOUNT_FAIL_UUID: 'DELETE_ACCOUNT_FAIL_UUID',

    DEACTIVATE_ACCOUNT: 'DEACTIVATE_ACCOUNT',
    DEACTIVATE_ACCOUNT_SUCCESS: 'DEACTIVATE_ACCOUNT_SUCCESS',
    DEACTIVATE_ACCOUNT_FAIL: 'DEACTIVATE_ACCOUNT_FAIL',

    DEACTIVATE_ACCOUNT_UUID: 'DEACTIVATE_ACCOUNT_UUID',
    DEACTIVATE_ACCOUNT_SUCCESS_UUID: 'DEACTIVATE_ACCOUNT_SUCCESS_UUID',
    DEACTIVATE_ACCOUNT_FAIL_UUID: 'DEACTIVATE_ACCOUNT_FAIL_UUID'
}

export default Actions