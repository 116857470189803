
const homeActions = {
    //home api call
    ON_HOME_API_FETCHED: 'ON_HOME_API_FETCHED',
    ON_HOME_API_FETCHED_FAIL: 'ON_HOME_API_FETCHED_FAIL',
    ON_HOME_API_FETCHED_SUCCESS: 'ON_HOME_API_FETCHED_SUCCESS',

    HOME_TABLE_LOADING_STATUS: 'HOME_TABLE_LOADING_STATUS',
}

export default homeActions;