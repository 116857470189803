import { takeLatest, call, put } from "redux-saga/effects";
import Api from "../../../shared/utils/Api";
import Actions from "./store/actions";

function changePasswordApiFetch(data) {
  return Api.put("account/change-password", data);
}

function* watchChangePasswordAsync(details) {
  try {
    yield call(changePasswordApiFetch, details.data);
    yield put({
      type: Actions.CHANGE_PASSWORD_SUCCESS,
    });
  } catch ({ errors }) {
    yield put({
      type: Actions.CHANGE_PASSWORD_FAILED,
      data: errors,
    });
  }
}

const changePasswordSaga = [
  takeLatest(Actions.ON_CHANGE_PASSWORD_SUBMIT, watchChangePasswordAsync),
];
export default changePasswordSaga;
