import React, { useEffect, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { isAuthenticated } from "./shared/utils/Authentication";
import { fetchSettingsApi } from "./shared/store/dispatchers";
import { authenticateRouteRenderForHome } from "./shared/utils/AuthenticateRouteRender";

const Home = lazy(() => import("./screens/components/Home"));
const News = lazy(() => import("./screens/components/News"));
const Login = lazy(() => import("./screens/components/Login"));
const SignUp = lazy(() => import("./screens/components/SignUp"));
const Profile = lazy(() => import("./screens/components/Profile"));
const Contact = lazy(() => import("./screens/components/Contact"));
const Timeline = lazy(() => import("./screens/components/Timeline"));
const Dashboard = lazy(() => import("./screens/components/Dashboard"));
const NotFoundPage = lazy(() => import("./screens/components/NotFound/index"));
const ResetPassword = lazy(() => import("./screens/components/ResetPassword"));
const PrivacyPolicy = lazy(() =>
  import("./screens/components/PrivacyPolicy/PrivacyPolicy.js")
);
const ForgotPassword = lazy(() =>
  import("./screens/components/ForgotPassword")
);
const ChangePassword = lazy(() =>
  import("./screens/components/ChangePassword")
);
const TimelineResources = lazy(() =>
  import("./screens/components/TimelineResources")
);
const StatementOfBenefits = lazy(() =>
  import("./screens/components/StatementOfBenefits")
);
const TransitionProgramSteps = lazy(() =>
  import("./screens/components/TransitionProgramSteps")
);
const WeeklyNewsletterUnsubscribe = lazy(() =>
  import("./screens/components/WeeklyNewsletterUnsubscribe")
);
const VeteranEmployers = lazy(() =>
  import("./screens/components/VeteranEmployers/index.js")
);
const EmployerIndustry = lazy(() =>
  import("./screens/components/VeteranEmployers/EmployerIndustry/index.js")
);
const CompanyDetails = lazy(() =>
  import("./screens/components/VeteranEmployers/CompanyDetails/index.js")
);

const DeleteAccount = lazy(() =>
  import("./screens/components/DeleteAccount/index.js")
);

const DynamicDeleteEmail = lazy(() =>
  import("./screens/components/DeleteAccount/DynamicDeleteEmail/index.js")
);

const DynamicDeactivateEmail = lazy(() =>
  import("./screens/components/DeleteAccount/DynamicDeactivateEmail/index.js")
);

function Main({ loginSuccess, fetchSettingsApi }) {
  useEffect(() => {
    const isUserLoggedIn = isAuthenticated();
    if (loginSuccess || isUserLoggedIn) {
      fetchSettingsApi();
    }
  }, [fetchSettingsApi, loginSuccess]);

  return (
    <Suspense fallback={<div>Loading... </div>}>
      <Switch>
        <Route
          path="/"
          render={(_) => {
            return authenticateRouteRenderForHome(Home, Dashboard);
          }}
          exact
        />
        <Route path="/news" component={News} exact />
        <Route path="/profile" component={Profile} exact />
        <Route path="/contact" component={Contact} exact />
        <Route path="/timeline" component={Timeline} exact />
        <Route path="/change-password" component={ChangePassword} exact />
        <Route path="/steps/:stepId" component={TransitionProgramSteps} exact />
        <Route path="/account/deactivate/:id" component={DynamicDeactivateEmail} exact />
        <Route path="/account/delete/:id" component={DynamicDeleteEmail} exact />
        <Route
          path="/statement-of-benefits"
          component={StatementOfBenefits}
          exact
        />
        <Route
          path="/resources/:resourceUrl"
          component={TimelineResources}
          exact
        />
        <Route
          path="/veteran-friendly-employers"
          component={VeteranEmployers}
          exact
        />
        <Route
          path="/veteran-friendly-employers/employer-industry"
          component={EmployerIndustry}
          exact
        />
        <Route
          path="/veteran-friendly-employers/employer-industry/company-details"
          component={CompanyDetails}
          exact
        />

        <Route path="/spouse-checklist" component={Timeline} exact />
        <Route
          path="/spouse-checklist/steps/:stepId"
          component={TransitionProgramSteps}
          exact
        />

        <Route path="/login" component={Login} exact />
        <Route path="/deleteAccount" component={DeleteAccount} exact />
        <Route path="/privacyPolicy" component={PrivacyPolicy} exact />
        <Route path="/signup" component={SignUp} exact />
        <Route path="/forgot-password" component={ForgotPassword} exact />
        <Route path="/reset-password/:uuid" component={ResetPassword} exact />
        <Route
          path="/weekly-newsletters/unsubscribe"
          component={WeeklyNewsletterUnsubscribe}
          exact
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
}

const mapStateToProps = ({ login, dashboard }) => {
  return {
    loginSuccess: login.loginSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSettingsApi() {
      dispatch(fetchSettingsApi());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Main));
