import Actions from "./actions";

const defaultState = {
  signUpFail: false,
  signUpFetched: false,
  signUpSuccess: false,
  details: {},
  nameOfCommand: "",

  nameOfCommandFail: false,
  nameOfCommandFetched: false,
  nameOfCommandSuccess: false,

  emailVerificationOTP: null,
  emailVerificationInProgress: false,
  emailVerificationSuccess: false,
  emailVerificationFailed: false,
};
export default function signUpReducer(state = defaultState, action) {
  switch (action.type) {
    case Actions.ON_RESET_STATE:
      return {
        ...defaultState,
      };
    case Actions.ON_SIGN_UP:
      return {
        ...state,
        signUpFail: false,
        signUpFetched: true,
        signUpSuccess: false,
      };
    case Actions.ON_SIGN_UP_SUCCESS:
      return {
        ...state,
        details: action.details,
        signUpFail: false,
        signUpFetched: false,
        signUpSuccess: true,
      };
    case Actions.ON_SIGN_UP_FAIL:
      return {
        ...state,
        signUpFail: true,
        signUpFetched: false,
        signUpSuccess: false,
      };
    case Actions.FETCH_NAME_OF_COMMAND:
      return {
        ...state,
        nameOfCommandFail: false,
        nameOfCommandFetched: true,
        nameOfCommandSuccess: false,
      };
    case Actions.FETCH_NAME_OF_COMMAND_SUCCESS:
      const { details } = action.details;
      return {
        ...state,
        nameOfCommand: details.nameOfCommand,
        nameOfCommandFail: false,
        nameOfCommandFetched: false,
        nameOfCommandSuccess: true,
      };
    case Actions.FETCH_NAME_OF_COMMAND_SUCCESS_FAILED:
      return {
        ...state,
        nameOfCommandFail: true,
        nameOfCommandFetched: false,
        nameOfCommandSuccess: false,
      };

    // email verification
    case Actions.ON_EMAIL_VERIFICATION:
      return {
        ...state,
        emailVerificationFailed: false,
        emailVerificationInProgress: true,
        emailVerificationSuccess: false,
      };
    case Actions.ON_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        emailVerificationOTP: action.data.details.emailVerificationOTP,
        emailVerificationFailed: false,
        emailVerificationInProgress: false,
        emailVerificationSuccess: true,
      };
    case Actions.ON_EMAIL_VERIFICATION_FAIL:
      return {
        ...state,
        emailVerificationFailed: true,
        emailVerificationInProgress: false,
        emailVerificationSuccess: false,
      };
    default:
      return state;
  }
}
