import Actions from './actions';

const defaultState = {
    homeDetails: null,
    homeApiFetch: false,
    homeApiFetchFail: false,
    homeApiFetchSuccess: false,

    tableLoadingStatus: false
}

export default function HomeReducer(state = defaultState, action) {
    switch (action.type) {
        case Actions.HOME_TABLE_LOADING_STATUS:
            return {
                ...state,
                tableLoadingStatus: action.data
            };
        case Actions.ON_HOME_API_FETCHED:
            return {
                ...state,
                homeApiFetch: true,
                homeApiFetchSuccess: false,
                homeApiFetchFail: false
            };
        case Actions.ON_HOME_API_FETCHED_SUCCESS:
            return {
                ...state,
                homeApiFetch: false,
                homeApiFetchSuccess: true,
                homeApiFetchFail: false,
                homeDetails: action.details
            };
        case Actions.ON_HOME_API_FETCHED_FAIL:
            return {
                ...state,
                homeApiFetch: false,
                homeApiFetchSuccess: false,
                homeApiFetchFail: true
            };
        default:
            return state;
    }
}