import { takeLatest, call, put } from "redux-saga/effects";
import Actions from "./store/actions";
import Api from "../../../shared/utils/Api";
import Toastr from "../../../shared/utils/Toastr";

function onSingUpApi(details) {
  return Api.post("account/register", details);
}

function verifyEmailApi(data) {
  return Api.post("account/signup/email/verification", data);
}

function fetchNameOfCommandApi({ source }) {
  return Api.get(`account/command-counselor/${source}/name-of-command`);
}

function* watchEmailVerificationAsync(selectedEmail) {
  try {
    const data = yield call(verifyEmailApi, { email: selectedEmail.data });
    yield put({
      type: Actions.ON_EMAIL_VERIFICATION_SUCCESS,
      data,
    });
  } catch (error) {
    Toastr.error(error);
    yield put({
      type: Actions.ON_EMAIL_VERIFICATION_FAIL,
      data: error,
    });
  }
}

function* watchSingUpAsync(data) {
  try {
    const details = yield call(onSingUpApi, data.data);
    yield put({
      type: Actions.ON_SIGN_UP_SUCCESS,
      details,
    });
    Toastr.success(`Account created successfully.!!`);
  } catch ({ errors }) {
    Toastr.error(errors.message);
    yield put({
      type: Actions.ON_RESET_STATE,
    });
    yield put({
      type: Actions.ON_SIGN_UP_FAIL,
      data: errors,
    });
  }
}

function* watchNameOfCommandAsync(data) {
  try {
    const details = yield call(fetchNameOfCommandApi, data.data);
    yield put({
      type: Actions.FETCH_NAME_OF_COMMAND_SUCCESS,
      details,
    });
  } catch ({ errors }) {
    Toastr.error(errors.message);
    yield put({
      type: Actions.ON_RESET_STATE,
    });
    yield put({
      type: Actions.FETCH_NAME_OF_COMMAND_SUCCESS_FAILED,
      data: errors,
    });
  }
}

const signUpSaga = [
  takeLatest(Actions.ON_SIGN_UP, watchSingUpAsync),
  takeLatest(Actions.FETCH_NAME_OF_COMMAND, watchNameOfCommandAsync),
  takeLatest(Actions.ON_EMAIL_VERIFICATION, watchEmailVerificationAsync),
];
export default signUpSaga;
