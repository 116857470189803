import Actions from "./actions";

const defaultState = {
  loginFail: false,
  loginFetched: false,
  loginSuccess: false,
  sessionDetails: null,

  errorMsg: "",

  getLoginInProgress: false,
  getLoginSuccess: false,
  getLoginFail: false,
  getLoginData: null,
};

export default function loginReducer(state = defaultState, action) {
  switch (action.type) {
    case Actions.ON_RESET_STATE:
      return {
        ...defaultState,
      };
    case Actions.UPDATE_LOGIN_FETCH:
      return {
        ...state,
        loginFail: false,
        loginFetched: true,
        loginSuccess: false,
      };
    case Actions.UPDATE_LOGIN_FORM_SUCCESS:
      return {
        ...state,
        loginSuccess: true,
        loginFail: false,
        loginFetched: false,
        sessionDetails: action.details,
      };
    case Actions.UPDATE_LOGIN_FORM_FAIL:
      return {
        ...state,
        loginFail: true,
        loginFetched: false,
        loginSuccess: false,
        errorMsg: action.data,
      };
    case Actions.ON_RESET_LOGIN:
      return { ...defaultState };

    case Actions.GET_LOGIN_FETCH:
      return {
        ...state,
        getLoginInProgress: true,
        getLoginSuccess: false,
        getLoginFail: false,
      };
    case Actions.GET_LOGIN_FETCH_SUCCESS:
      return {
        ...state,
        getLoginInProgress: false,
        getLoginSuccess: true,
        getLoginFail: false,
        getLoginData: action.details,
      };
    case Actions.GET_LOGIN_FETCH_FAIL:
      return {
        ...state,
        getLoginInProgress: false,
        getLoginSuccess: false,
        getLoginFail: true,
      };

    default:
      return state;
  }
}
