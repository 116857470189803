import { takeLatest, call, put } from "redux-saga/effects";
import Api from "../../../shared/utils/Api";
import Actions from "./store/actions";
import Toastr from "../../../shared/utils/Toastr";

function fetchProfileDetails() {
  return Api.get("account/profile");
}
function profileDelete(id) {
  return Api.get(`account/profile/${id}`);
}
function updateProfile(data) {
  return Api.put("account/profile", data);
}

function uploadProfileImage(data) {
  return Api.post("file/upload", data);
}

function removeProfileImage() {
  return Api._delete("account/profile/image");
}

function fetchCMVRCenters(locations) {
  const queryParams = locations.map((location) => {
    return [`locations[]=${encodeURIComponent(location)}`];
  });
  return Api.get(`cmvr-centers?${queryParams.join("&")}`);
}

function assignCMVRCenter(data) {
  return Api.post("cmvr-centers", data);
}

function unassignCMVRCenter() {
  return Api._delete("cmvr-centers");
}

function verifyProfileEmailApi(data) {
  return Api.post("account/signup/email/verification", data);
}

function* watchProfileDeleteAsync(data){
  try{
   const details = yield call(profileDelete, data)
   yield put({
    type: Actions.ON_PROFILE_DELETE_SUCCESS,
    details
   })
   Toastr.success('Account deleted successfully')
  } catch (error){
      yield put({
        type: Actions.ON_PROFILE_DELETE_SUCCESS_FAIL,
        error
      })
    Toastr.error(error.message)
  }
}

function* watchProfileEmailVerificationAsync(selectedEmail) {
  try {
    const data = yield call(verifyProfileEmailApi, { email: selectedEmail.data });
    yield put({
      type: Actions.ON_PROFILE_EMAIL_VERIFICATION_SUCCESS,
      data,
    });
  } catch (error) {
    Toastr.error(error);
    yield put({
      type: Actions.ON_PROFILE_EMAIL_VERIFICATION_FAIL,
      data: error,
    });
  }
}

function* watchFetchProfileAsync() {
  try {
    const data = yield call(fetchProfileDetails);
    yield put({
      type: Actions.FETCH_PROFILE_SUCCESS,
      details: data.details,
    });
  } catch ({ errors }) {
    Toastr.error(errors.message);
    yield put({
      type: Actions.FETCH_PROFILE_FAILED,
      data: errors,
    });
  }
}

function* watchUpdateProfileAsync(data) {
  try {
    yield call(updateProfile, data.data);
    yield put({
      type: Actions.UPDATE_PROFILE_SUCCESS,
    });
    yield put({
      type: Actions.SET_ASSIGNED_CMVR_DETAILS,
      details: {
        cmvrCenterId: data.data.selectedCMVRCenterId,
      },
    });
  } catch ({ errors }) {
    yield put({
      type: Actions.UPDATE_PROFILE_FAILED,
      data: errors,
    });
  }
}
function* watchUploadProfileImageAsync(data) {
  try {
    const details = yield call(uploadProfileImage, data.data);
    yield put({
      type: Actions.UPLOAD_PROFILE_IMAGE_SUCCESS,
      details: {
        profileUuid: details.details.uuid,
      },
    });
  } catch ({ errors }) {
    Toastr.error(errors.message);
    yield put({
      type: Actions.UPLOAD_PROFILE_IMAGE_FAILED,
      data: errors,
    });
  }
}

function* watchRemoveProfileImageAsync() {
  try {
    const details = yield call(removeProfileImage);
    yield put({
      type: Actions.REMOVE_PROFILE_IMAGE_SUCCESS,
      data: details,
    });
  } catch ({ errors }) {
    Toastr.error(errors.message);
    yield put({
      type: Actions.REMOVE_PROFILE_IMAGE_FAILED,
      data: errors,
    });
  }
}

function* watchFetchCMVRCenters(data) {
  try {
    const details = yield call(fetchCMVRCenters, data.data.locations);
    yield put({
      type: Actions.FETCH_CMVR_CENTERS_SUCCESS,
      details,
    });
  } catch ({ errors }) {
    Toastr.error(errors.message);
    yield put({
      type: Actions.FETCH_CMVR_CENTERS_FAILED,
      data: errors,
    });
  }
}

function* watchAssignCMVRCenter(data) {
  try {
    yield call(assignCMVRCenter, data.data);
    yield put({
      type: Actions.ASSIGN_CMVR_CENTER_SUCCESS,
    });
    yield put({
      type: Actions.SET_ASSIGNED_CMVR_DETAILS,
      details: data.data,
    });
  } catch ({ errors }) {
    yield put({
      type: Actions.ASSIGN_CMVR_CENTER_FAILED,
      data: errors,
    });
  }
}

function* watchUnassignCMVRCenter() {
  try {
    yield call(unassignCMVRCenter);
    yield put({
      type: Actions.UNASSIGN_CMVR_CENTER_SUCCESS,
    });
  } catch ({ errors }) {
    yield put({
      type: Actions.UNASSIGN_CMVR_CENTER_FAILED,
      data: errors,
    });
  }
}

const profileSaga = [
  takeLatest(Actions.FETCH_PROFILE, watchFetchProfileAsync),
  takeLatest(Actions.UPLOAD_PROFILE_IMAGE, watchUploadProfileImageAsync),
  takeLatest(Actions.UPDATE_PROFILE, watchUpdateProfileAsync),
  takeLatest(Actions.REMOVE_PROFILE_IMAGE, watchRemoveProfileImageAsync),
  takeLatest(Actions.FETCH_CMVR_CENTERS, watchFetchCMVRCenters),
  takeLatest(Actions.ASSIGN_CMVR_CENTER, watchAssignCMVRCenter),
  takeLatest(Actions.UNASSIGN_CMVR_CENTER, watchUnassignCMVRCenter),
  takeLatest(Actions.ON_PROFILE_EMAIL_VERIFICATION, watchProfileEmailVerificationAsync),
  takeLatest(Actions.ON_PROFILE_DELETE, watchProfileDeleteAsync),
];
export default profileSaga;
