import Actions from "./actions";

let defaultState = {
  settingDetails: null,
  settingFetched: false,
  settingFetchedFail: false,
  settingFetchedSuccess: false,

  dashboardFetchInProgress: false,
  dashboardFetchSuccess: false,
  dashboardFetchFailed: false,
  dashboardDetails: null,

  feedbackDataFetchInProgress: false,
  feedbackDataFetchSuccess: false,
  feedbackDataFetchFailed: false,
  feedbackDetails: null,

  updateEAOSDateInProgress: false,
  updateEAOSDateSuccess: false,
  updateEAOSDateFailed: false,
};

export default function settingReducers(state = defaultState, action) {
  switch (action.type) {
    case Actions.RESET_DASHBOARD:
      return {
        ...state,
        dashboardFetchInProgress: false,
        dashboardFetchSuccess: false,
        dashboardFetchFailed: false,
      };

    case Actions.FETCH_SETTINGS_API:
      return {
        ...state,
        settingFetched: true,
        settingFetchedSuccess: false,
        settingFetchedFail: false,
      };
    case Actions.ON_SETTING_FETCHED_SUCCESS:
      return {
        ...state,
        settingFetched: false,
        settingFetchedSuccess: true,
        settingFetchedFail: false,
        settingDetails: action.details,
      };
    case Actions.ON_SETTING_FETCHED_FAIL:
      return {
        ...state,
        settingFetched: false,
        settingFetchedSuccess: false,
        settingFetchedFail: true,
      };
    case Actions.ON_DASHBOARD_API_FETCH:
      return {
        ...state,
        dashboardFetchInProgress: true,
        dashboardFetchSuccess: false,
        dashboardFetchFailed: false,
      };
    case Actions.ON_DASHBOARD_API_FETCH_SUCCESS:
      return {
        ...state,
        dashboardFetchInProgress: false,
        dashboardFetchSuccess: true,
        dashboardFetchFailed: false,
        dashboardDetails: action.details,
      };
    case Actions.ON_DASHBOARD_API_FETCH_FAIL:
      return {
        ...state,
        dashboardFetchInProgress: false,
        dashboardFetchSuccess: false,
        dashboardFetchFailed: true,
      };
    case Actions.ON_FEEDBACK_DATA_FETCH:
      return {
        ...state,
        feedbackDataFetchInProgress: true,
        feedbackDataFetchSuccess: false,
        feedbackDataFetchFailed: false,
      };
    case Actions.ON_FEEDBACK_DATA_FETCH_SUCCESS:
      return {
        ...state,
        feedbackDataFetchInProgress: false,
        feedbackDataFetchSuccess: true,
        feedbackDataFetchFailed: false,
        feedbackDetails: action.details,
      };
    case Actions.ON_FEEDBACK_DATA_FETCH_FAIL:
      return {
        ...state,
        feedbackDataFetchInProgress: false,
        feedbackDataFetchSuccess: false,
        feedbackDataFetchFailed: true,
      };
    case Actions.UPDATE_PROFILE_EAOS_DATE:
      return {
        ...state,
        dashboardDetails: {
          ...state.dashboardDetails,
          eosDate: action.data.eosDate,
        },
        updateEAOSDateInProgress: true,
        updateEAOSDateSuccess: false,
        updateEAOSDateFailed: false,
      };
    case Actions.UPDATE_PROFILE_EAOS_DATE_SUCCESS:
      return {
        ...state,
        updateEAOSDateInProgress: false,
        updateEAOSDateSuccess: true,
        updateEAOSDateFailed: false,
      };
    case Actions.UPDATE_PROFILE_EAOS_DATE_FAILED:
      return {
        ...state,
        updateEAOSDateInProgress: false,
        updateEAOSDateSuccess: false,
        updateEAOSDateFailed: true,
      };
    default:
      return state;
  }
}
