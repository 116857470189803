import { takeLatest, call, put } from "redux-saga/effects";
import Actions from "./store/actions";
import API from "../../../shared/utils/Api";
import toastr from "../../../shared/utils/Toastr";

function deleteAccountApiCall(details) {
  return API.post("account/delete", details);
}

function deleteAccountIdApiCall({data, id}) {
  return API.post(`account/delete/${id}`, data);
}

function deactivateAccountApiCall(details) {
  return API.post("account/deactivate", details);
}

function deactivateAccountIdApiCall({data, id}) {
  return API.post(`account/deactivate/${id}`, data);
}

function* watchDeleteAccountAsync(data) {
  try {
    yield call(deleteAccountApiCall, data.data);
    yield put({
      type: Actions.DELETE_ACCOUNT_SUCCESS,
    });
    toastr.success('Delete Email is sent')
  } catch ( error ) {
    yield put({
      type: Actions.DELETE_ACCOUNT_FAIL,
      data: error,
    });
    toastr.error(error.message)
  }
}

function* watchDeleteAccountIdAsync(data) {
  try {
    yield call(deleteAccountIdApiCall, data);
    yield put({
      type: Actions.DELETE_ACCOUNT_SUCCESS_UUID,
    });
    toastr.success('Account Deleted')
  } catch ( error ) {
    yield put({
      type: Actions.DELETE_ACCOUNT_FAIL_UUID,
      data: error,
    });
    toastr.error(error.message)
  }
}

function* watchDeactivateAccountAsync(data) {
  try {
    yield call(deactivateAccountApiCall, data.data);
    yield put({
      type: Actions.DEACTIVATE_ACCOUNT_SUCCESS,
    });
    toastr.success('Deactivate Email is sent')
  } catch (error) {
    yield put({
      type: Actions.DEACTIVATE_ACCOUNT_FAIL,
      data: error,
    });
    toastr.error(error.message)
  }
}

function* watchDeactivateAccountIdAsync(data) {
  try {
    yield call(deactivateAccountIdApiCall, data);
    yield put({
      type: Actions.DEACTIVATE_ACCOUNT_SUCCESS_UUID,
    });
    toastr.success('Account Deactivated')
  } catch (error) {
    yield put({
      type: Actions.DEACTIVATE_ACCOUNT_FAIL_UUID,
      data: error,
    });
    toastr.error(error.message)
  }
}

const deleteSaga = [
  takeLatest(Actions.DELETE_ACCOUNT, watchDeleteAccountAsync),
  takeLatest(Actions.DEACTIVATE_ACCOUNT, watchDeactivateAccountAsync),
  takeLatest(Actions.DELETE_ACCOUNT_UUID, watchDeleteAccountIdAsync),
  takeLatest(Actions.DEACTIVATE_ACCOUNT_UUID, watchDeactivateAccountIdAsync),
];

export default deleteSaga;
