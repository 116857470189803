import Actions from "./actions";

const defaultState = {
  getCitiesLoading: false,
  getCitiesSuccess: false,
  getCitiesFail: false,
  getCitiesData: null,

  getIndustryByCityLoading: false,
  getIndustryByCitySuccess: false,
  getIndustryByCityFail: false,
  getIndustryByCityData: null,

  getIndustryNameId: {
    name: null,
    Id: null,
  },

  getIndustryCityBothLoading: false,
  getIndustryCityBothSuccess: false,
  getIndustryCityBothFail: false,
  getIndustryCityBothData: null,

  getCompanyDetailsLoading: false,
  getCompanyDetailsSuccess: false,
  getCompanyDetailsFail: false,
  getCompanyDetailsData: null,
};

function employerReducer(state = defaultState, action) {
  switch (action.type) {
    case Actions.GET_CITIES:
      return {
        ...state,
        getCitiesLoading: true,
        getCitiesSuccess: false,
        getCitiesFail: false,
      };
    case Actions.GET_CITIES_SUCCESS:
      return {
        ...state,
        getCitiesLoading: false,
        getCitiesSuccess: true,
        getCitiesFail: false,
        getCitiesData: action.details,
      };
    case Actions.GET_CITIES_FAIL:
      return {
        ...state,
        getCitiesLoading: false,
        getCitiesSuccess: false,
        getCitiesFail: true,
      };
    case Actions.GET_INDUSTRY_BY_CITY:
      return {
        ...state,
        getIndustryByCityLoading: true,
        getIndustryByCitySuccess: false,
        getIndustryByCityFail: false,
      };
    case Actions.GET_INDUSTRY_BY_CITY_SUCCESS:
      return {
        ...state,
        getIndustryByCityLoading: false,
        getIndustryByCitySuccess: true,
        getIndustryByCityFail: false,
        getIndustryByCityData: action.details,
      };
    case Actions.GET_INDUSTRY_BY_CITY_FAIL:
      return {
        ...state,
        getIndustryByCityLoading: false,
        getIndustryByCitySuccess: false,
        getIndustryByCityFail: true,
      };
    case Actions.GET_INDUSTRY_ID_BOTH:
      return {
        ...state,
        getIndustryCityBothLoading: true,
        getIndustryCityBothSuccess: false,
        getIndustryCityBothFail: false,
      };
    case Actions.GET_INDUSTRY_ID_BOTH_SUCCESS:
      return {
        ...state,
        getIndustryCityBothLoading: false,
        getIndustryCityBothSuccess: true,
        getIndustryCityBothFail: false,
        getIndustryCityBothData: action.details,
      };
    case Actions.GET_INDUSTRY_ID_BOTH_FAIL:
      return {
        ...state,
        getIndustryCityBothLoading: false,
        getIndustryCityBothSuccess: false,
        getIndustryCityBothFail: true,
      };
    case Actions.GET_COMPANY_DETAILS:
      return {
        ...state,
        getCompanyDetailsLoading: true,
        getCompanyDetailsSuccess: false,
        getCompanyDetailsFail: false,
      };
    case Actions.GET_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        getCompanyDetailsLoading: false,
        getCompanyDetailsSuccess: true,
        getCompanyDetailsFail: false,
        getCompanyDetailsData: action.details,
      };
    case Actions.GET_COMPANY_DETAILS_FAIL:
      return {
        ...state,
        getCompanyDetailsLoading: false,
        getCompanyDetailsSuccess: false,
        getCompanyDetailsFail: true,
      };

    case Actions.GET_INDUSTRY_NAME_ID:
      return {
        ...state,
        getIndustryNameId: {
          name: action.data.name,
          Id: action.data.Id,
        },
      };

    default:
      return {
        ...state,
      };
  }
}

export default employerReducer;
