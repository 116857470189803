import { takeLatest, call, put } from "redux-saga/effects";
import Api from "../../../../shared/utils/Api";
import Actions from "./store/actions";
import Toastr from "../../../../shared/utils/Toastr";

function fetchTimelineList(isSpouseChecklist) {
  return Api.get(`timeline?isSpouseChecklist=${isSpouseChecklist}`);
}

function* watchFetchTimelineList(data) {
  try {
    const payload = yield call(
      fetchTimelineList,
      data.details.isSpouseChecklist
    );
    payload.details.isSpouseChecklist = data.details.isSpouseChecklist;
    yield put({
      type: Actions.FETCH_TIMELINE_LIST_SUCCESS,
      payload,
    });
  } catch ({ errors }) {
    Toastr.error(errors.message);
    yield put({
      type: Actions.FETCH_TIMELINE_LIST_FAILED,
      data: errors,
    });
  }
}

const transitionProgramSteps = [
  takeLatest(Actions.FETCH_TIMELINE_LIST, watchFetchTimelineList),
];

export default transitionProgramSteps;
