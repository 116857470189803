import Actions from "./actions";

const defaultState = {
  changePasswordInProgress: false,
  changePasswordSuccess: false,
  changePasswordFailed: false,
  changePasswordError: null,
};

function changePassword(state = defaultState, action) {
  switch (action.type) {
    case Actions.ON_CHANGE_PASSWORD_SUBMIT:
      return {
        ...state,
        changePasswordInProgress: true,
        changePasswordSuccess: false,
        changePasswordFailed: false,
        changePasswordError: null,
      };
    case Actions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordInProgress: false,
        changePasswordSuccess: true,
        changePasswordFailed: false,
      };
    case Actions.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        changePasswordInProgress: false,
        changePasswordSuccess: false,
        changePasswordFailed: true,
        changePasswordError: action.data,
      };
    case Actions.RESET_CHANGE_PASSWORD_STATE:
      return { ...defaultState };
    default:
      return { ...state };
  }
}

export default changePassword;
