import Actions from './action';

const defaultState = {
    forgotPasswordFetch: false,
    forgotPasswordFetchedSuccess: false,
    forgotPasswordFetchedFail: false,
    forgotPasswordDetails: {}
}
export default function forgotPasswordReducer(state = defaultState, action) {
    switch (action.type) {
        case Actions.ON_RESET_STATE: 
            return {
                ...defaultState,
            }
        case Actions.UPDATE_FORGOT_PASSWORD_DETAILS:
            return {
                ...state
            }
        case Actions.UPDATE_FORGOT_PASSWORD_FETCHED:
            return {
                ...state,
                forgotPasswordFetch: true,
                forgotPasswordFetchedSuccess: false,
                forgotPasswordFetchedFail: false,
            }
        case Actions.UPDATE_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgotPasswordFetch: false,
                forgotPasswordFetchedSuccess: true,
                forgotPasswordFetchedFail: false,
                forgotPasswordDetails: action.details
            }
        case Actions.UPDATE_FORGOT_PASSWORD_FAIL:
            return {
                forgotPasswordFetch: false,
                forgotPasswordFetchedSuccess: false,
                forgotPasswordFetchedFail: true,
                forgotPasswordDetails: action.details
            }
        default:
            return state;
    }
}