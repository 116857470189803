import { ActionType } from "../../../shared/types/redux";
import { takeLatest, call, put } from "redux-saga/effects";
import Actions from "./store/actions";
import Api from "../../../shared/utils/Api";
import { UnsubscribeFromWeeklyNewsletterRequestPayloadType } from "./type";
import { AxiosResponse } from "axios";

function unsubscribe(payload: any) {
  return Api.post(`/weekly-newsletters/unsubscribe?key=${payload.key}`);
}

function* watch(
  data: ActionType<UnsubscribeFromWeeklyNewsletterRequestPayloadType>
) {
  try {
    const details: AxiosResponse = yield call(unsubscribe, data.payload);
    yield put({
      type: Actions.UNSUBSCRIBE_FROM_WEEKLY_NEWSLETTER_SUCCESS,
      details,
    });
  } catch ({ errors }) {
    yield put({
      type: Actions.UNSUBSCRIBE_FROM_WEEKLY_NEWSLETTER_FAILED,
      data: errors,
    });
  }
}

const saga = [takeLatest(Actions.UNSUBSCRIBE_FROM_WEEKLY_NEWSLETTER, watch)];

export default saga;
